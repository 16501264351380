import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import "./Home.css";
import { Toast, Popup } from "../../Utils/Notifications";
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import CustomPrimaryButton from "../../components/Inputs/CustomPrimaryButton";
// import FullWidthTabs, { TabPanel } from "../../components/customTabs";
import SwipeableViews from "react-swipeable-views";
import NewsUpdates from "../../components/NewsUpdates";
import Footer from "../../components/Footer";
import AuctionCard from "../../components/AuctionCard";
import Slider from "react-slick";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import RecentAuctions from "../../components/RecentAuctions";
import { SettingsBackupRestoreRounded } from "@material-ui/icons";
import { request } from "../../Utils/Request";
import moment from "moment";
import { isAuthenticated } from "../../Utils/Authenticated";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { sDate } from "../../Utils/TimerProfile";
import JwtDecode from "jwt-decode";
import GridView from "../Search/GridView";
import FFModal from "../../components/Modal";
import { Button, useMediaQuery } from "@material-ui/core";
import AuctionList from "../../components/AuctionCard/AuctionList";
// import Intercom   from 'react-intercom';

// onLeave(origin, destination, direction) {
//   console.log("Leaving section " + origin.index);
// }
// afterLoad(origin, destination, direction) {
//   console.log("After load: " + destination.index);
// }

// function CustomNextArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", background: "red" }}
//       onClick={onClick}
//     />
//   );
// }

// function CustomPrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", background: "green" }}
//       onClick={onClick}
//     />
//   );
// }

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const Home = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);
  const history = useHistory();
  const [welcomepopup, setWlecomepop] = useState(false);
  const [product_loading, setProductloading] = useState(false);
  const [init_load, setLoad] = useState(false);
  const [soldIcon, setSoldIcon] = useState(null);
  const [values, setValues] = useState({
    email: "",
  });
  const matches = useMediaQuery("(max-width:600px)");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const hanchange = async (e) => {
    var { id, checked } = e.target;
    console.log("+++++++", id, checked);

    if (checked) {
      const res = await request(
        "post",
        "/watchlist",
        { id: id, userid: JwtDecode(isAuthenticated()).id },
        false,
        true
      );

      // console.log("+++++",res)
      if ((res.data.success = "yes")) {
        Toast.fire({ type: "success", title: "Lot added to favourite" });
      } else {
        Toast.fire({ type: "error", title: "Something went wrong" });
      }
    } else {
      const res = await request(
        "post",
        "/removeFwatchlist",
        { id: id, userid: JwtDecode(isAuthenticated()).id },
        false,
        true
      );

      // console.log("+++++",res)
      if ((res.data.success = "yes")) {
        Toast.fire({ type: "success", title: "Lot removed from Favorite" });
      } else {
        Toast.fire({ type: "error", title: "Something went wrong" });
      }
    }
  };

  useEffect(() => {
    fetch("./svg/soldIcon.svg")
      .then((response) => response.text())
      .then((data) => setSoldIcon(data));
  }, []);

  const handleRedirect = (path) => {
    history.push(`/${path}`);
  };

  // trustap login

  const search = props.location.search;
  const params = new URLSearchParams(search);
  const authority = params.get("code");
  const state = params.get("state");
  console.log("code", authority);
  SendCode(authority, state);

  async function SendCode(code, state) {
    if (history.location.pathname.includes("icbf/receivecode")) {
      console.log("================icbf_login", props.location.search);
      if (code) {
        try {
          console.log("================icbf_login code", code);
          const res = await request("post", "/codetobackend", {
            code: code,
            userid: localStorage.userId,
            url: process.env.REACT_APP_FRONT_URL + "/icbf/receivecode/",
            type: "icbf",
          });
          if (res.data.success) {
            localStorage.setItem("icbfAccessToken", res.data.code);
            window.opener = null;
            window.open("", "_self");
            window.close();
          } else {
            // for test only, remove when push to live
            localStorage.setItem("icbfAccessToken", "1");
            window.opener = null;
            window.open("", "_self");
            window.close();
          }
        } catch (err) {
          console.log(err);
        }
      }
    } else {
      if (code && state && state.includes("checkout")) {
        console.log("================state", state);
        window.location.href = state + "?trustap_code=" + code;
        // props.history.push({
        //   pathname: state + '?trustap_code=' + code,
        // });
      } else if (code && state && state.includes("mobile_request")) {
        console.log("================mobile_request", state);
      } else if (code) {
        try {
          console.log(code);
          const res = await request("post", "/codetobackend", {
            code: code,
            userid: localStorage.userId,
            url: process.env.REACT_APP_FRONT_URL,
          });
          if (res.data.success) {
            localStorage.setItem("trustapID", res.data.code);
            localStorage.setItem("trustapIDToken", "1");
            if (state && state == "close") {
              window.opener = null;
              window.open("", "_self");
              window.close();
            }
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  const [initialvalue, setinitialvalue] = useState({
    /* sh_limit: 8, */
    category: [], //Sections filter
    location: [], // Province search
    closed_auction_list: 0,
    subcategory: [], //type is covered
    weight: [], //Weight needs to be mapped
    contenthead5: [], //Sex filter
    contenthead2: [], //DOB to be mapped as inventorydate or a new date
    contenthead1: [], //Breed filter
    contenthead3: [],
    orderby: 2,
    fromprice: "",
    toprice: "",
    QA: "", //??
    export: "", //??
    searchbar: "", //Keyword
  });

  const [liveauction, setLiveauction] = useState([]);
  const [upcomming, setUpcomming] = useState([]);
  const [Recent, SetRecent] = useState([]);
  const [culf, SetCulf] = useState([]);
  const [modalopen2, setModalopen] = useState(false);

  async function Search() {
    try {
      const res = await request("post", "/mobilesearch_new", initialvalue);
      var arr1 = [],
        arr2 = [],
        arr3 = [];
      var cdate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

      if (res.data.success === "yes") {
        res.data.results.map((val) => {
          //console.log("++", cdate, val.date_added);
          if (moment(val.date_added).format("YYYY-MM-DD HH:mm:ss") > cdate) {
            arr2.push(val);
          }
          if (
            val.market_status == "open" &&
            moment(val.date_added).format("YYYY-MM-DD HH:mm:ss") < cdate
          ) {
            arr1.push(val);
          }
          if (val.market_status == "sold") {
            arr3.push(val);
          }
        });
        // arr1.push(arr1)
        setLiveauction(arr1);
        setUpcomming(arr2);

        if (arr1.length > 0) {
          setValue(0);
        } else if (arr2.length > 0) {
          setValue(1);
        } else {
          setValue(2);
        }
      }
      setProductloading(true);
    } catch (err) {
      console.log(err);
    }
  }
  async function recent_list() {
    const sold = await request(
      "post",
      "/mobilesearch_new",
      { closed_auction_list: 1 },
      false,
      true
    );
    SetRecent(sold.data.results);
    setLoad(true);
  }
  async function calf_list() {
    const culf_res = await request(
      "post",
      "/mobilesearch_new",
      {
        category: ["Calf"],
      },
      false,
      true
    );
    SetCulf(culf_res.data.results);
  }
  const moveon = (e) => {
    if (!isAuthenticated()) {
      props.history.push("/Reg_tier_1?video=true");
    } else {
      props.history.push("/refer-and-earn");
    }
  };
  const moveon1 = (e) => {
    props.history.push("/search");
  };
  const moveon2 = (e) => {
    props.history.push("/Reg_tier_1");
  };

  useEffect(() => {
    Search();
    recent_list();
    calf_list();
  }, []);

  const handlechange = (e) => {
    setValues({ ...values, email: e.target.value });
  };

  const onsubmit = async (e) => {
    if (!/\S+@\S+\.\S+/.test(values.email)) {
      Toast.fire({ type: "error", title: "Please Enter Valid Email id!" });
    } else {
      if (!window.location.hostname.includes("localhost")) {
        const res = await request("post", "/mailchimp", {
          type: "mailchimp",
          email: values.email,
        });
        if (res.data.success) {
          setValues({ ...values, email: "" });
          setWlecomepop(false);
          return Popup.fire({ type: "success", title: "Thanks for Subscribe" });
        } else {
          return Toast.fire({ type: "error", title: res.data.msg });
        }
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated()) {
      setWlecomepop(false);
    } else {
      setWlecomepop(false);
    }
  }, []);

  const liveauctionSettings = {
    rows:
      parseInt(liveauction.length / 4) > 3
        ? 4
        : parseInt(liveauction.length / 4) == 0
        ? 1
        : parseInt(liveauction.length / 4),
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 4,
    pauseOnHover: true,
    slidesToScroll: 1,
    adaptiveHeight: false,
    autoplay: false,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    slidesPerRow: 4,
    responsive: [
      {
        breakpoint: 2400,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesPerRow: 1,
          rows: 4,
        },
      },
      // {
      //   breakpoint: 480,
      //   settings: {
      //     slidesToShow: 2,
      //     rows: 1,
      //   },
      // },
    ],
  };
  const handleClose1 = () => {
    setWlecomepop(false);
  };
  const upcommingSettings = {
    rows: upcomming.length > 6 ? 2 : 1,
    dots: false,
    arrows: true,
    infinite: false,
    draggable: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: false,
    autoplay: false,
    pauseOnHover: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 2400,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const RecentSettings = {
    rows: upcomming.length > 6 ? 2 : 1,
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: false,
    autoplay: false,
    pauseOnHover: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 2400,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      // {
      //   breakpoint: 480,
      //   settings: {
      //     slidesToShow: 2,
      //     rows: 1,
      //   },
      // },
    ],
  };

  const moveonepop = (name) => (e) => {
    if (name == "log") {
      window.location.href = "/login";
    } else {
      if (localStorage.getItem("userRole") === "tier1") {
        window.location.href = "/Reg_tier_2";
      } else {
        window.location.href = "/Reg_tier_1";
      }
    }
  };
  const toggleModal1 = (e) => {
    setModalopen(false);
  };

  return (
    <div className="ffHome">
      <div id="fullpage-wrapper">
        <Header />
        <div className="ffBanner">
          <div className="bannerContent">
            <h1>
              <span className="farmfayre">Farm-to-Farm</span> Cattle Sales
            </h1>
            <p>
              Quick, Easy and Secure Field-to-Field Cattle Sales
              <br />
              {/* where you can buy and sell livestock in a safe, secure and
              transparent environment. */}
            </p>
            <div>
              {!isAuthenticated() && (
                <CustomPrimaryButton
                  label="Register Now"
                  iconCenter={
                    <span>
                      <img src="/svg/logo.svg" alt="Farm Fayre" height="36" />
                    </span>
                  }
                  // tagline1="Stay up to date."
                  // tagline2="Takes less than 30 seconds."
                  // tagline3="List your Cattle for sale in minutes."
                  tagline1="Watch Our Explainer Video here"
                  name={"reg"}
                  onsubmit={(e) => moveon(e)}
                />
              )}
              {/* {!isAuthenticated() ? (
                <div className="searchBtn w-100">
                  <PrimaryButton
                    iconLeft={
                      <span>
                        <img src="/svg/logo.svg" alt="Farm Fayre" height="25" />
                      </span>
                    }
                    label="Watch Our Explainer Video here"
                    name={"reg"}
                    onsubmit={(e) => moveon(e)}
                  />
                </div>
              ) : (''
              )} */}
              {/* <div className="searchBtn w-100">
                <PrimaryButton
                  iconLeft={<span className="material-icons">search</span>}
                  label="Search Here"
                  name={"search"}
                  onsubmit={(e) => moveon1(e)}
                />
              </div> */}
            </div>
          </div>
        </div>
        <div className="ffProducts customContainer">
          {product_loading ? (
            <>
              <div className="customTabs">
                <AppBar position="static">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    aria-label="simple tabs example"
                  >
                    <Tab label="Live Sales" {...a11yProps(0)} />
                    <Tab label={"Upcoming"} {...a11yProps(1)} />
                    <Tab label={"Recent"} {...a11yProps(2)} />
                    <Tab label="Calf Hub" {...a11yProps(3)} />
                    <Tab label="All Sales" {...a11yProps(4)} />
                    {/* <Tab
                  label="Search environment"
                  onClick={() => handleRedirect("search")}
                  {...a11yProps(3)}
                /> */}
                  </Tabs>
                </AppBar>

                <TabPanel value={value} index={0}>
                  {liveauction.length > 0 ? (
                    // <Slider {...liveauctionSettings}>
                    //   {liveauction.map((val) => (
                    //     <AuctionCard data={val} onchange={hanchange} />
                    //   ))}
                    // </Slider>
                    <GridView searchData={liveauction.slice(0, 16)} />
                  ) : (
                    <>
                      <h2 className="noProductsFound text-left">
                        No Live Sales Found !
                      </h2>
                    </>
                  )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {upcomming.length > 0 ? (
                    <>
                      {matches ? (
                        <>
                          <GridView searchData={upcomming.slice(0, 16)} />
                        </>
                      ) : (
                        <Slider {...upcommingSettings}>
                          {upcomming.map((val) => (
                            <>
                              <AuctionCard data={val} onchange={hanchange} />
                            </>
                          ))}
                        </Slider>
                      )}
                    </>
                  ) : (
                    <h2 className="noProductsFound text-left">
                      No Upcoming Sales Found !
                    </h2>
                  )}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {Recent.length > 0 ? (
                    <>
                      {matches ? (
                        <>
                          {Recent.map((val) => (
                            <RecentAuctions data={val} soldIcon={soldIcon} />
                          ))}
                          {/* <GridView searchData={Recent.slice(0, 16)} value={value} /> */}
                        </>
                      ) : (
                        <Slider {...RecentSettings}>
                          {Recent.map((val) => (
                            <RecentAuctions data={val}  soldIcon={soldIcon} />
                          ))}
                        </Slider>
                      )}
                    </>
                  ) : (
                    <>
                      {init_load ? (
                        <h2 className="noProductsFound text-left">
                          No Recent Sales Found !
                        </h2>
                      ) : (
                        <h2 className="noProductsFound text-left">
                          Loading...
                        </h2>
                      )}
                    </>
                  )}
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <h3 className="allAuctionTab">Calf Hub Sales</h3>
                  {culf.length > 0 ? (
                    <>
                      {matches ? (
                        <>
                          <GridView searchData={culf.slice(0, 16)} />
                        </>
                      ) : (
                        <Slider {...RecentSettings}>
                          {culf.map((val) => (
                            <AuctionCard data={val} onchange={hanchange} />
                          ))}
                        </Slider>
                      )}
                    </>
                  ) : (
                    <h2 className="noProductsFound text-left">
                      No Calf Hub Sales Found !
                    </h2>
                  )}
                </TabPanel>
                <TabPanel value={value} index={4} className="allLiveSales">
                  {liveauction.length > 0 ? (
                    <>
                      <h3 className="allAuctionTab">Live Sales</h3>

                      {matches ? (
                        <>
                          <GridView searchData={liveauction.slice(0, 16)} />
                        </>
                      ) : (
                        <Slider {...RecentSettings}>
                          {liveauction.map((val) => (
                            <AuctionCard
                              setModalopen={setModalopen}
                              modalopen2={modalopen2}
                              data={val}
                              onchange={hanchange}
                            />
                          ))}
                        </Slider>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  <br />
                  {upcomming.length > 0 ? (
                    <>
                      <h3 className="allAuctionTab">Upcoming Sales</h3>
                      {matches ? (
                        <>
                          <GridView searchData={upcomming.slice(0, 16)} />
                        </>
                      ) : (
                        <Slider {...upcommingSettings}>
                          {upcomming.map((val) => (
                            <>
                              <AuctionCard
                                setModalopen={setModalopen}
                                modalopen2={modalopen2}
                                data={val}
                                onchange={hanchange}
                              />
                            </>
                          ))}
                        </Slider>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  <br />
                  {(liveauction.length == 0 || upcomming.length == 0) &&
                  Recent.length > 0 ? (
                    <>
                      <h3 className="allAuctionTab">Recent Sales</h3>
                      <Slider {...RecentSettings}>
                        {Recent.map((val) => (
                          <div>
                            <RecentAuctions data={val} />
                          </div>
                        ))}
                      </Slider>
                    </>
                  ) : (
                    ""
                  )}
                </TabPanel>
              </div>

              {(liveauction.length < 9 && value == 1) ||
              (upcomming.length < 8 && value == 2) ? (
                <div className="customTabs">
                  {Recent.length > 0 ? (
                    <>
                      <h3 className="allAuctionTab">Recent Sales</h3>
                      {matches ? (
                        <>
                          {Recent.map((val) => (
                            <RecentAuctions data={val} />
                          ))}
                          {/* <GridView searchData={Recent.slice(0, 16)} /> */}
                        </>
                      ) : (
                        <Slider {...RecentSettings}>
                          {Recent.map((val) => (
                            <RecentAuctions data={val} />
                          ))}
                        </Slider>
                      )}
                    </>
                  ) : (
                    <>
                      {init_load ? (
                        <h2 className="noProductsFound text-left">
                          No Recent Sales Found !
                        </h2>
                      ) : (
                        <h2 className="noProductsFound text-left">
                          Loading...
                        </h2>
                      )}
                    </>
                  )}
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <h2 className="noProductsFound text-left">Loading...</h2>
          )}
        </div>
        <div className="referEarn d-flex justify-content-between align-items-center">
          <div className="referContent">
            <h1 className="playFair">Refer & Earn</h1>
            <div>
              <p>
                <span style={{ fontWeight: "600" }}>
                  Refer & Earn - Introduce a Friend and Earn €100 CASH!
                </span>
                <br></br>
                Bring fellow farmers into Farm Fayre's revolutionary ecosystem,
                and reap rewards for both buying and selling.
                <br></br>
                <span style={{ fontWeight: "600" }}>Start referring now!</span>
              </p>
            </div>
            {!isAuthenticated() ? (
              <PrimaryButton
                label="Register To Get Started"
                onsubmit={moveon}
              />
            ) : (
              <PrimaryButton label="Share Farm Fayre" onsubmit={moveon} />
            )}
          </div>
          <img src="/images/referEarn.png" width="220" alt="" />
        </div>
        <div className="bg-white playStore">
          <h1 className="playFair">Download Our App for Seamless Trading</h1>
          {/* <img src={"/images/hands.png"} style={{rotate : "4deg"}} /> */}
          <div className="storeIcons">
            <a
              href={"https://apps.apple.com/us/app/farm-fayre/id1560086069"}
              target="_blank"
            >
              <img className="play-img" src={"/images/appleplaystore.png"} />
            </a>
            <a
              href={
                "https://play.google.com/store/apps/details?id=com.auctionsoftware.farmfayre"
              }
              target="_blank"
            >
              <img className="play-img" src={"/images/googleplaystore.png"} />
            </a>
          </div>
          <h3 className="playFair">Available on iOS & Android</h3>
          <h5 className="playFair join">
            <i>
              "Join the revolution in livestock sales, right from your pocket."
            </i>
          </h5>
        </div>
        <div className="homeFoot">
          <NewsUpdates live={Recent} />
          <Footer />
        </div>
      </div>
      <FFModal
        open={modalopen2}
        handleClose={toggleModal1}
        modaltitle="Message"
      >
        <div style={{ fontWeight: "500" }}>
          <p className="card-text">
            If you want to add Lot in favourite, please register. Already
            Registered? Login here.
          </p>

          <div className="modalCTA d-flex justify-content-end align-items-center">
            <Button onClick={moveonepop("log")}>Login</Button>

            <PrimaryButton label={"Register"} onsubmit={moveonepop("Reg")} />
          </div>
        </div>
      </FFModal>
      <FFModal
        open={welcomepopup}
        className="welcome_Popup"
        noHeader
        // modaltitle="Message"
      >
        <div style={{ fontWeight: "500" }}>
          <button type="button" className="close" onClick={handleClose1}>
            <i className="fa fa-times"></i>
          </button>
          <div className="Wlpop">
            <p>Welcome to Farm Fayre!</p>
            <p>🐄 Revolutionising Buying & Selling Livestock 🐄</p>
          </div>
          <div className="Wlpopup">
            <p>Enter your email to Stay Ahead of the Herd!</p>
            <div class="input-group mb-2">
              <input
                type="text"
                onChange={handlechange}
                value={values.email}
                class="form-control"
                placeholder="Enter your email"
                aria-label="Enter your email"
                // aria-describedby="button-addon2"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  type="button"
                  id="button-addon2"
                  onClick={onsubmit}
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
      </FFModal>
    </div>
  );
};

export default Home;
