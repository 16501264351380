import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Toast, SwalAlert } from "../../Utils/Notifications";
import {
  ValidateMultipleLot,
  ValidatePostSingleLot,
} from "../../Utils/Validators";
import SearchOptions from "../../components/Inputs/SearchOptions";
import imageCompression from "browser-image-compression";
import UseForm from "../../Utils/UserForm";
import DatePicker from "../../Utils/DatePicker";
import { request } from "../../Utils/Request";
import InputAdornment from "@material-ui/core/InputAdornment";
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import TransButton from "../../components/Inputs/TransButton";
import { GreenRadio } from "../../components/Inputs/RadioBox";
import CheckBox from "../../components/Inputs/CheckBox";
import CustomInput from "../../components/Inputs/borderedInput";
import CustomSelect from "../../components/Inputs/borderedSelect";
import Upload from "../../components/Upload";
import "./post-project.css";
import Layout from "../../components/Layout";
import {
  Button,
  FormHelperText,
  ListItemSecondaryAction,
  useMediaQuery,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import JwtDecode from "jwt-decode";
import { isAuthenticated } from "../../Utils/Authenticated";
import {
  Breed,
  Sex,
  Owners,
  TextOnly,
  specialcharacter,
  Crossbreed,
  BreedICBF,
} from "../../Utils/constant";
import { keys } from "@material-ui/core/styles/createBreakpoints";
import FFModal from "../../components/Modal";
import { id } from "date-fns/locale";
import { validate } from "@material-ui/pickers";
import { TimetoDays, TimetoDate } from "../../Utils/TimerProfile";
import { CircularProgress } from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import { AvatarURL } from "../../Utils/constant";
import moment from "moment";
let key = 0;
let v;
var errors = {
  multiItems_breed: [],
  multiItems_calfReg: [],
  multiItems_notes: [],
  multiItems_sex: [],
  multiItems_tbStatus: [],
  multiItems_weight: [],
  multiItems_testdate: [],
  multiItems_prevowner: [],
  multiItems_export: [],
  multiItems_tagnumber: [],
  multiItems_cardissued: [],
  multiItems_dob: [],
  product_image: [],
};

var formData = new FormData();
const MultipleItemsStep2 = (props) => {
  const [values, setValues] = useState({
    yek: "",
    multiItems_breed: [],
    collection_breed: "",
    join_breed: "",
    multiItems_calfReg: [],
    multiItems_notes: [],
    multiItems_sex: [],
    multiItems_tbStatus: [],
    multiItems_weight: [],
    multiItems_testdate: [],
    multiItems_prevowner: [],
    multiItems_export: [],
    multiItems_tagnumber: [],
    multiItems_cardissued: [],
    multiItems_dob: [],
    product_image: [[]],
    terms: "",
    ter: "",
    bre: "",
  });

  let history = useHistory();

  const [data, setData] = useState([props.history.location.state.val]);
  const [state, setState] = useState(false);
  const [modalopen2, setModalopen] = useState(false);
  const [modalopen3, setModalopen3] = useState(false);
  const [modalopen4, setModalopen4] = useState(false);
  const [modalopen5, setModalopen5] = useState(false);
  const [loading, setLoading] = useState(false);
  const [item, Additem] = useState({
    k: [0],
  });
  const [content, setContent] = useState("");
  const getContent = async (e) => {
    const res = await request("post", "/show_static_content", {
      page_id: "Seller's Declaration",
    });
    if (res && res.data.status == "true") {
      const search = props.location.search;
      const params = new URLSearchParams(search);

      var html = res.data.data[0].content.replace(
        "{seller}",
        localStorage.userFirstName + " " + localStorage.userLastName
      );
      html = html.replace("{lot}", data[0].multiItems_title);
      setContent(html);
    }
  };

  const toggleModal2 = () => {
    setModalopen(!modalopen2);
  };
  const toggleModal3 = () => {
    setModalopen3(!modalopen3);
  };
  const toggleModal4 = () => {
    setModalopen4(!modalopen4);
  };
  const toggleModal5 = () => {
    setModalopen5(!modalopen5);
  };
  const callcommand = () => {
    Breed.push(values.collection_breed);
    values.multiItems_breed[v].push(values.collection_breed);
    values.collection_breed = "";

    setValues({ ...values });
    setModalopen(!modalopen2);
  };
  const callcommand1 = () => {
    // console.log("++++++++++++",v)
    // Breed.push(values.collection_breed[0]+","+values.collection_breed[1])
    // values.multiItems_breed.push(values.collection_breed+",");
    // values.join_breed=values.collection_breed;
    //  values.collection_breed="";
    if (!values.collection_breed) {
      return setValues({ ...values, bre: "Please sellect Breed # 1" });
    }

    setValues({
      ...values,
      join_breed: values.collection_breed,
      collection_breed: "",
      bre: "",
    });
    setModalopen3(!modalopen3);
    setModalopen4(!modalopen4);
  };
  const finalsubmit = () => {
    if (!values.collection_breed) {
      return setValues({ ...values, bre: "Please sellect Breed # 2" });
    }
    Breed.push(values.join_breed + "," + values.collection_breed);
    values.multiItems_breed[v].push(
      values.join_breed + "," + values.collection_breed
    );
    // values.multiItems_breed.splice(
    //   v,
    //   0,
    //   values.join_breed + "," + values.collection_breed
    // );
    // values.multiItems_breed[v].push(values.join_breed, values.collection_breed);
    values.collection_breed = "";
    values.bre = "";
    setValues({ ...values });
    setModalopen4(!modalopen4);
  };
  const handleChange1 = (val) => (e) => {
    const { name, value } = e.target;

    if (value.some((item) => item === "Other") && name === "multiItems_breed") {
      v = val;
      return toggleModal2();
    }
    if (
      value.some((item) => item === "Crossbreed") &&
      name === "multiItems_breed"
    ) {
      v = val;
      return toggleModal3();
    }

    if (values[name][val] === undefined) {
      setValues((prevState) => ({
        ...prevState,
        [name]: [...prevState[name], value],
      }));
    }
    //  else if(name === "multiItems_weight" || name === "multiItems_tagnumber" || name==="multiItems_prevowner" || name ==="multiItems_notes"){
    //   values[name].splice(id,1,value);
    //   setValues({...values})
    //  }
    else {
      values[name].splice(val, 1, value);
      setValues({ ...values });
    }
  };
  const handleChange = (e) => {
    const { id, name, value } = e.target;

    //  if(values.[name][key])
    if (value === "Other" && name === "multiItems_breed") {
      v = id;
      toggleModal2();
    }
    if (value === "Crossbreed" && name === "multiItems_breed") {
      v = id;
      return toggleModal3();
    }

    if (name !== "terms") {
      if (values[name][id] === undefined) {
        setValues((prevState) => ({
          ...prevState,
          [name]: [...prevState[name], value],
        }));
      }
      //  else if(name === "multiItems_weight" || name === "multiItems_tagnumber" || name==="multiItems_prevowner" || name ==="multiItems_notes"){
      //   values[name].splice(id,1,value);
      //   setValues({...values})
      //  }
      else {
        values[name].splice(id, 1, value);
        setValues({ ...values });
      }
    } else {
      setValues({ ...values, terms: e.target.checked, ter: "" });
    }

    //  errorchange()
  };
  const breedchage = (e) => {
    // if(e.target.value.length <= 2){

    setValues({ ...values, collection_breed: e.target.value });
    // }
  };
  // const errorchange=()=>{
  //   if(Object.keys(errors.multiItems_breed).length === 0 && Object.keys(errors.multiItems_sex).length === 0 && Object.keys(errors.multiItems_weight).length === 0 && Object.keys(errors.multiItems_dob).length === 0 && Object.keys(errors.multiItems_tagnumber).length === 0 && Object.keys(errors.multiItems_testdate).length === 0 && Object.keys(errors.multiItems_tbStatus).length === 0 && Object.keys(errors.multiItems_cardissued).length === 0 && Object.keys(errors.multiItems_prevowner).length === 0 && Object.keys(errors.multiItems_export).length === 0 && Object.keys(errors.multiItems_calfReg).length === 0 && Object.keys(errors.multiItems_notes).length === 0)
  //   {
  //     console.log("+++++++++++++++++0+k")

  //   }
  //   else{
  //     // setValues({...values})

  //     ValidateMultipleLot(values);
  //     setState(true)

  //   }
  // }

  const ValidateMultipleLot = (values) => {
    // if (values.multiItems_section===) {
    //   errors.multiItems_section = "Please select section!";
    // }
    item &&
      item.k.map((key) => {
        if (
          values.multiItems_breed[key] === undefined ||
          !values.multiItems_breed[key]
        ) {
          errors.multiItems_breed[key] = "Please select breed!";
        } else {
          // errors.multiItems_breed[key]
          errors.multiItems_breed.splice(
            errors.multiItems_breed.indexOf(key),
            1
          );
        }
        // if (
        //   values.multiItems_tbStatus[key] === undefined || !values.multiItems_tbStatus[key]
        // ) {
        //   errors.multiItems_tbStatus[key] = "Please select tbstatus!";
        // } else {
        //   errors.multiItems_tbStatus.splice(
        //     errors.multiItems_tbStatus.indexOf(key),
        //     1
        //   );
        // }
        if (
          values.multiItems_export[key] === undefined ||
          !values.multiItems_export[key]
        ) {
          errors.multiItems_export[key] = "Please select export!";
        } else {
          errors.multiItems_export.splice(
            errors.multiItems_export.indexOf(key),
            1
          );
        }
        if (
          values.multiItems_calfReg[key] === undefined ||
          !values.multiItems_calfReg[key]
        ) {
          errors.multiItems_calfReg[key] = "Please select Calf Reg!";
        } else {
          errors.multiItems_calfReg.splice(
            errors.multiItems_calfReg.indexOf(key),
            1
          );
        }
        // if (values.multiItems_notes[key]===undefined) {
        //   errors.multiItems_notes[key] = "Please enter notes!";
        // }
        // else{
        //   errors.multiItems_notes.splice(errors.multiItems_notes.indexOf(key), 1);
        // }

        if (
          values.multiItems_sex[key] === undefined ||
          !values.multiItems_sex[key]
        ) {
          errors.multiItems_sex[key] = "Please select sex!";
        } else {
          errors.multiItems_sex.splice(errors.multiItems_sex.indexOf(key), 1);
        }
        if (data[0].sale_method == "Not Sold by Weight") {
          errors.multiItems_weight.splice(
            errors.multiItems_weight.indexOf(key),
            1
          );
        } else if (
          values.multiItems_weight[key] === undefined ||
          !values.multiItems_weight[key]
        ) {
          errors.multiItems_weight[key] = "Please add weight!";
        } else {
          errors.multiItems_weight.splice(
            errors.multiItems_weight.indexOf(key),
            1
          );
        }
        if (
          values.multiItems_dob[key] === undefined ||
          !values.multiItems_dob[key]
        ) {
          errors.multiItems_dob[key] = "Please add Date of birth!";
        } else if (
          values.multiItems_dob[key] < data[0].multiItems_first_dob ||
          values.multiItems_dob[key] > data[0].multiItems_first_dob1
        ) {
          Toast.fire({
            type: "warning",
            title:
              "DOB cannot be older than “Oldest DOBs”, or younger than “Youngest DOB”!",
          });
          errors.multiItems_dob[key] =
            "DOB cannot be older than “Oldest DOBs”, or younger than “Youngest DOB!";
          console.log("else if", errors.multiItems_dob[key]);
        } else {
          delete errors.multiItems_dob[key];
        }
        // else {
        //   console.log(" came to else")
        //   errors.multiItems_dob.splice(errors.multiItems_dob.indexOf(key), 1);
        //   console.log("error 2",errors.multiItems_dob)
        // }
        // // if (!values.start_date) {
        // //   errors.start_date = "Please Select Auction start Date";
        // // }
        // // if (!values.end_date) {
        // //   errors.end_date = "Please Select Auction end Date";
        // // }

        if (
          values.multiItems_tagnumber[key] === undefined ||
          !values.multiItems_tagnumber[key]
        ) {
          errors.multiItems_tagnumber[key] = "Please add tag number!";
        } else {
          errors.multiItems_tagnumber.splice(
            errors.multiItems_tagnumber.indexOf(key),
            1
          );
        } /* else if (!/^([0-9\-\s]){14,18}$/i.test(values.multiItems_tagnumber)){
  //       errors.multiItems_tagnumber = 'Invalid tag number!'
  //   } */

        if (
          values.multiItems_cardissued[key] === undefined ||
          !values.multiItems_cardissued[key]
        ) {
          errors.multiItems_cardissued[key] = "Please add card issued date!";
        } else if (
          data[0].multiItems_first_movement > 0 &&
          (values.multiItems_cardissued[key] <
            data[0].multiItems_first_cardissued ||
            values.multiItems_cardissued[key] >
              data[0].multiItems_first_cardissued1)
        ) {
          Toast.fire({
            type: "warning",
            title:
              "Date into herd cannot be older than Oldest Movement Date, or more recent than Most Recent Movement!",
          });
          errors.multiItems_cardissued[key] =
            "Date into herd cannot be older than Oldest Movement Date, or more recent than Most Recent Movement!";
        } else {
          delete errors.multiItems_cardissued[key];
        }
        //  else {
        //   errors.multiItems_cardissued.splice(
        //     errors.multiItems_cardissued.indexOf(key),
        //     1
        //   );
        // }
        if (
          values.multiItems_testdate[key] === undefined ||
          !values.multiItems_testdate[key]
        ) {
          errors.multiItems_testdate[key] = "Please add last test date!";
        } else {
          errors.multiItems_testdate.splice(
            errors.multiItems_testdate.indexOf(key),
            1
          );
        }
        if (values.product_image.length > 0) {
          if (!values.product_image[key]?.length) {
            errors.product_image[key] = "Minimum one image needed!";
          } else {
            errors.product_image.splice(errors.product_image.indexOf(key), 1);
          }
        }
        if (
          values.multiItems_prevowner[key] === undefined ||
          !values.multiItems_prevowner[key]
        ) {
          errors.multiItems_prevowner[key] =
            "Please select no previous movement!";
        } else {
          errors.multiItems_prevowner.splice(
            errors.multiItems_prevowner.indexOf(key),
            1
          );
        }
        // // // if (!values.multiItems_premiums) {
        // // //   errors.multiItems_premiums = "Please add 0 if no premiums!";
        // // // }
        // // // if (!values.multiItems_truck_turn) {
        // // //   errors.multiItems_truck_turn = "Please select one!";
        // // // }
      });
    return errors;
  };

  // var formdata={}
  const [modalopen1, setModelopen1] = React.useState(false);
  const toggleModal1 = () => {
    setModelopen1(!modalopen1);
  };

  const addtoitem = (e) => {
    const type = props.history.location.state.val.multiItems_type;
    const section = props.history.location.state.val.multiItems_section;

    if (section == "Calf") {
      if (key >= 4) {
        setModelopen1(true);
      } else {
        Toast.fire({
          type: "warning",
          title: "Please add minimum of 5 items!",
        });
      }
    } else if (type != "Cow & Calf" && section != "Pedigree") {
      if (key >= 2) {
        setModelopen1(true);
      } else {
        Toast.fire({
          type: "warning",
          title: "Please add minimum of 3 items!",
        });
      }
    } else {
      //  Cow & Calf min 4 items
      if (section != "Pedigree") {
        if (key < 3) {
          Toast.fire({
            type: "warning",
            title: "Please add minimum of 4 items!",
          });
        } else if (parseInt(key) % 2 == 0) {
          Toast.fire({
            type: "warning",
            title: "Please add 1 item.SubLot count should be even!",
          });
        } else {
          setModelopen1(true);
        }
      } else {
        if (key >= 0) {
          setModelopen1(true);
        } else {
          Toast.fire({
            type: "warning",
            title: "Please add atleast 1 item!",
          });
        }
      }
    }
  };

  const handleDateChange = (date, name, i) => {
    //   console.log("+++++++++++",date,name,i)
    // setValues({ ...values,[name]:{[i]:date}});
    if (values[name][i] === undefined) {
      setValues((prevState) => ({
        ...prevState,
        [name]: [...prevState[name], date],
      }));
    } else {
      values[name].splice(i, 1, date);
      setValues({ ...values });
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const confirmationMessage =
        "Are you sure you want to leave this page? All unsaved data will be lost";
      event.preventDefault();
      event.return = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (
      !localStorage.getItem("trustapIDToken") &&
      localStorage.getItem("trustapID") != ""
    ) {
      window.location.href = "/trustap-redirect-new";
    } else {
      if (localStorage.getItem("trustapID") != "") {
      // } else if (localStorage.getItem("trustapIDToken") != "") {
      //   window.location.href = "/trustap-redirect-new";
      } else {
        window.location.href = "/trustap-redirect";
      }
    }
    const s = props.history.location.state;

    if (s == undefined) {
      return props.history.push("/multiple-items-step1");
    }

    getContent();
    const UserProfile = async () => {
      try {
        const res = await request(
          "post",
          "/getUserProfileDetails",
          {
            user_id: localStorage.userId,
          },
          false,
          true
        );

        if (res.data.success === "yes") {
          setValues({
            ...values,
            present_bldgname: res.data.data.address1,
            present_stname: res.data.data.address2,
            present_town: res.data.data.city,
            present_country: res.data.data.country,
            present_county: res.data.data.state,
            present_eircode: res.data.data.zip,
            multiIteme_pickup_location: "present_address",
          });
        } else {
          console.log("error");
        }
      } catch (error) {
        console.log(error);
      }
    };
    // UserProfile();
    if (props.history.location.state.page2) {
      // values=;

      Additem({ ...item, k: props.history.location.state.ite });
      if (props.history?.location?.state?.ite) {
        values.product_image = [];
        props.history.location.state.ite.map((data) => {
          values.product_image.push([]);
        });
      }
      // props.history.location.state.ite.map((val)=>{
      //   values.product_image.push([])
      // })
      // setValues({...values})
      const page = props.history.location.state.page2;
      setValues(page);
    }

    errors = {
      multiItems_breed: [],
      multiItems_calfReg: [],
      multiItems_notes: [],
      multiItems_sex: [],
      multiItems_tbStatus: [],
      multiItems_weight: [],
      multiItems_testdate: [],
      multiItems_prevowner: [],
      multiItems_export: [],
      multiItems_tagnumber: [],
      multiItems_cardissued: [],
      multiItems_dob: [],
      product_image: [],
    };
  }, []);

  useEffect(() => {
    // if (
    //   props.history.location.state.val.multiItems_type === "Bull" ||
    //   props.history.location.state.val.multiItems_type === "Bullock"
    // ) {
    //   setValues({
    //     ...values,
    //     multiItems_sex: ["Male"],
    //     multiItems_prevowner: ["0"],
    //   });
    // }
    // if (
    //   props.history.location.state.val.multiItems_type === "Cow" ||
    //   props.history.location.state.val.multiItems_type === "Heifer"
    // ) {
    //   setValues({
    //     ...values,
    //     multiItems_sex: ["Female"],
    //     multiItems_prevowner: ["0"],
    //   });
    // }

    if (props.history.location.state.val) {
      const { val } = props.history.location.state;
      const numberOfAnimals = val.no_of_animals;

      let multiItems_calfReg = [];
      let multiItems_sex = [];
      let multiItems_weight = [];
      let multiItems_export = [];
      let multiItems_cardissued = [];
      let multiItems_dob = [];
      let multiItems_breed = [];
      let multiItems_prevowner = [];
      let multiItems_testdate = [];
      let product_image = [];
      let multiItems_tagnumber = [];

      const addmissingBreed = val.multiItems_first_breed.map((item) => {
        if (!Breed.includes(item)) {
          return Breed.push(item);
        }
      });

      const keys = [];
      const icbf_data = props.history.location.state.icbf_data || [];

      for (let i = 0; i < numberOfAnimals; i++) {
        keys.push(i);
        key = i;
        if (val.multiItems_icbf !== "yes" || i >= icbf_data.length) {
          multiItems_calfReg.push(
            val[`multiItems_first_calfReg_${i}`] || val.multiItems_first_calfReg
          );
          multiItems_sex.push(
            val[`multiItems_first_sex_${i}`] || val.multiItems_first_sex
          );
          multiItems_export.push(
            val[`multiItems_first_export_${i}`] || val.multiItems_first_export
          );
          multiItems_cardissued.push(
            val[`multiItems_first_cardissued`] ||
              val.multiItems_first_cardissued
          );
          multiItems_dob.push(
            val[`multiItems_first_dob`] || val.multiItems_first_dob
          );

          //   if (Array.isArray(val.multiItems_first_breed) && val.multiItems_first_breed.length > i) {
          //     multiItems_breed.push(val.multiItems_first_breed[i]);
          // } else {
          multiItems_breed.push(val.multiItems_first_breed);
          // }
          multiItems_prevowner.push(
            val[`multiItems_first_movement_${i}`] ||
              val.multiItems_first_movement
          );
          multiItems_testdate.push(
            val[`multiItems_first_old_date_${i}`] ||
              val.multiItems_first_old_date
          );
        } else {
          multiItems_tagnumber.push(val.icbf_number[i] || "");

          var sex =
            val[`multiItems_first_sex_${i}`] || val.multiItems_first_sex;
          if (icbf_data[i].details && icbf_data[i].details.sex) {
            sex = icbf_data[i].details.sex == "F" ? ["Female"] : ["Male"];
          }
          multiItems_sex.push(sex);

          var dob =
            i === 0
              ? val[`multiItems_first_dob`] || val.multiItems_first_dob
              : val[`multiItems_first_dob1`] || val.multiItems_first_dob1;
          if (icbf_data[i].details && icbf_data[i].details.birth_date) {
            dob = moment(icbf_data[i].details.birth_date);
          }
          multiItems_dob.push(dob);

          var breed = val.multiItems_first_breed;
          if (icbf_data[i].details && icbf_data[i].details.breed_data) {
            breed = icbf_data[i].details.breed_data;
          }
          multiItems_breed.push(breed);

          var movement =
            val[`multiItems_first_movement_${i}`] ||
            val.multiItems_first_movement;
          if (icbf_data[i].details && icbf_data[i].details.total_movements) {
            movement = icbf_data[i].details.total_movements;
            movement = movement > 5 ? [">5"] : [movement];
          }
          multiItems_prevowner.push(movement);

          multiItems_calfReg.push(
            val[`multiItems_first_calfReg_${i}`] || val.multiItems_first_calfReg
          );
          multiItems_export.push(
            val[`multiItems_first_export_${i}`] || val.multiItems_first_export
          );
          if (i === 0) {
            multiItems_cardissued.push(
              val[`multiItems_first_cardissued`] ||
                val.multiItems_first_cardissued
            );
          } else {
            multiItems_cardissued.push(
              val[`multiItems_first_cardissued1`] ||
                val.multiItems_first_cardissued1
            );
          }
          multiItems_testdate.push(
            val[`multiItems_first_old_date_${i}`] ||
              val.multiItems_first_old_date
          );
        }
        multiItems_weight.push(
          val[`multiItems_first_weight_${i}`] || val.multiItems_first_weight
        );
        product_image.push([]);
      }
      Additem((prevState) => ({ ...prevState, k: keys }));

      setValues({
        ...values,
        multiItems_calfReg,
        multiItems_sex,
        multiItems_weight,
        multiItems_export,
        multiItems_cardissued,
        multiItems_dob,
        multiItems_breed,
        multiItems_prevowner,
        multiItems_testdate,
        product_image,
        multiItems_tagnumber,
      });
      // //
      // setValues({
      //   ...values,
      //   multiItems_calfReg:
      //     props.history.location.state.val.multiItems_icbf != "yes"
      //       ? [props.history.location.state.val.multiItems_first_calfReg]
      //       : [],
      //   multiItems_sex:
      //     props.history.location.state.val.multiItems_icbf != "yes"
      //       ? [props.history.location.state.val.multiItems_first_sex]
      //       : [],
      //   multiItems_weight: [
      //     props.history.location.state.val.multiItems_first_weight,
      //   ],
      //   multiItems_export:
      //     props.history.location.state.val.multiItems_icbf != "yes"
      //       ? [props.history.location.state.val.multiItems_first_export]
      //       : [],
      //   multiItems_cardissued:
      //     props.history.location.state.val.multiItems_icbf != "yes"
      //       ? [props.history.location.state.val.multiItems_first_cardissued]
      //       : [],
      //   multiItems_dob:
      //     props.history.location.state.val.multiItems_icbf != "yes"
      //       ? [props.history.location.state.val.multiItems_first_dob]
      //       : [],
      //   multiItems_breed:
      //     props.history.location.state.val.multiItems_icbf != "yes"
      //       ? [props.history.location.state.val.multiItems_first_breed]
      //       : [],
      //   multiItems_prevowner:
      //     props.history.location.state.val.multiItems_icbf != "yes"
      //       ? [props.history.location.state.val.multiItems_first_movement]
      //       : [],
      //   multiItems_testdate:
      //     props.history.location.state.val.multiItems_icbf != "yes"
      //       ? [props.history.location.state.val.multiItems_first_old_date]
      //       : [],
      // });
    }
  }, [props.history.location.state.val]);

  const removeImage = (e, id) => {
    values.product_image[id].splice(values.product_image.indexOf(e), 1);
    setValues({ ...values });
  };

  // var {
  //   handleChange,
  //   handleSubmit,
  //   handleDateChange,
  //   values,
  //   errors,
  //   state,
  //   setValues,
  // } = UseForm(addtoitem("0"), ValidateMultipleLot);

  const removeitemchange = (e, key_val) => {
    var remove_arr_item1 = item.k,
      remove_arr_item2 = [];
    remove_arr_item1.map((data) => {
      if (data < key_val) {
        remove_arr_item2.push(data);
      }
      if (data > key_val) {
        remove_arr_item2.push(data - 1);
      }
    });
    Additem({ ...item, k: remove_arr_item2 });
    key = key - 1;
    Toast.fire({
      type: "success",
      title: `Item Removed Successfully.`,
    });
    //console.log("pppppppppppppppppppppppppppp", values)
    values.multiItems_breed.splice(key_val, 1);
    values.multiItems_calfReg.splice(key_val, 1);
    values.multiItems_cardissued.splice(key_val, 1);
    values.multiItems_dob.splice(key_val, 1);
    values.multiItems_export.splice(key_val, 1);
    values.multiItems_notes.splice(key_val, 1);
    values.multiItems_prevowner.splice(key_val, 1);
    values.multiItems_sex.splice(key_val, 1);
    values.multiItems_tagnumber.splice(key_val, 1);
    values.multiItems_tbStatus.splice(key_val, 1);
    values.multiItems_testdate.splice(key_val, 1);
    values.multiItems_weight.splice(key_val, 1);
    values.product_image.splice(key_val, 1);

    setValues({ ...values });
  };

  const additemchange = (e) => {
    key = key + 1;
    console.log("add key - ", key);
    // if (
    //   props.history.location.state.val.multiItems_type === "Bull" ||
    //   props.history.location.state.val.multiItems_type === "Bullock"
    // ) {
    //   values.multiItems_sex.splice(key, 1, "Male");
    //   //setValues({ ...values });
    // }
    // if (
    //   props.history.location.state.val.multiItems_type === "Cow" ||
    //   props.history.location.state.val.multiItems_type === "Heifer"
    // ) {
    //   values.multiItems_sex.splice(key, 1, "Female");
    //   //setValues({ ...values });
    // }
    if (
      values.multiItems_sex[0] != "" &&
      values.multiItems_sex[0] != undefined
    ) {
      values.multiItems_sex.splice(key, 1, values.multiItems_sex[0]);
    }
    if (
      values.multiItems_weight[0] != "" &&
      values.multiItems_weight[0] != undefined
    ) {
      values.multiItems_weight.splice(key, 1, values.multiItems_weight[0]);
    }
    if (
      values.multiItems_testdate[0] != "" &&
      values.multiItems_testdate[0] != undefined
    ) {
      values.multiItems_testdate.splice(key, 1, values.multiItems_testdate[0]);
    }
    if (
      values.multiItems_breed[0] != "" &&
      values.multiItems_breed[0] != undefined
    ) {
      values.multiItems_breed.splice(key, 1, values.multiItems_breed[0]);
    }
    // if(values.multiItems_weight[0] != ''){
    //   values.multiItems_weight.splice(key, 1, values.multiItems_weight[0])
    // }
    if (
      values.multiItems_export[0] != "" &&
      values.multiItems_export[0] != undefined
    ) {
      values.multiItems_export.splice(key, 1, values.multiItems_export[0]);
    }
    if (
      values.multiItems_calfReg[0] != "" &&
      values.multiItems_calfReg[0] != undefined
    ) {
      values.multiItems_calfReg.splice(key, 1, values.multiItems_calfReg[0]);
    }
    if (
      values.multiItems_dob[0] != "" &&
      values.multiItems_dob[0] != undefined
    ) {
      values.multiItems_dob.splice(key, 1, values.multiItems_dob[0]);
    }
    if (
      values.multiItems_dob[0] != "" &&
      values.multiItems_dob[0] != undefined
    ) {
      values.multiItems_dob.splice(key, 1, values.multiItems_dob[0]);
    }
    if (
      values.multiItems_cardissued[0] != "" &&
      values.multiItems_cardissued[0] != undefined
    ) {
      values.multiItems_cardissued.splice(
        key,
        1,
        values.multiItems_cardissued[0]
      );
    }
    if (
      values.multiItems_prevowner[0] != "" &&
      values.multiItems_prevowner[0] != undefined
    ) {
      values.multiItems_prevowner.splice(
        key,
        1,
        values.multiItems_prevowner[0]
      );
    }
    values.product_image.push([]);
    setValues({ ...values });

    Additem({ ...item, k: [...item.k, key] });
    console.log("ITEM ____ ", item);
  };
  // console.log("page 2 values",values)
  const handleSubmit = (e) => {
    //  console.log("++++++++++++",e.target.value)
    ValidateMultipleLot(values);
    console.log("errors", errors);
    setState(true);
    setValues({ ...values, yek: key });
    if (
      Object.keys(errors.multiItems_breed).length === 0 &&
      Object.keys(errors.multiItems_sex).length === 0 &&
      Object.keys(errors.multiItems_weight).length === 0 &&
      Object.keys(errors.multiItems_dob).length === 0 &&
      Object.keys(errors.multiItems_tagnumber).length === 0 &&
      Object.keys(errors.multiItems_testdate).length === 0 &&
      // Object.keys(errors.multiItems_tbStatus).length === 0 &&
      Object.keys(errors.multiItems_cardissued).length === 0 &&
      Object.keys(errors.multiItems_prevowner).length === 0 &&
      Object.keys(errors.multiItems_export).length === 0 &&
      Object.keys(errors.multiItems_calfReg).length === 0 &&
      Object.keys(errors.multiItems_notes).length === 0 &&
      Object.keys(errors.product_image).length === 0
    ) {
      console.log("+++++++++++++++++0+k");
      addtoitem(e);
    }
  };

  // const handleSubmit1=(e)=>{
  //   //  console.log("++++++++++++",e.target.value)
  //     ValidateMultipleLot(values)
  //     setState(true)
  //     setValues({...values,yek:key})
  //    if(Object.keys(errors.multiItems_breed).length === 0 && Object.keys(errors.multiItems_sex).length === 0 && Object.keys(errors.multiItems_weight).length === 0 && Object.keys(errors.multiItems_dob).length === 0 && Object.keys(errors.multiItems_tagnumber).length === 0 && Object.keys(errors.multiItems_testdate).length === 0 && Object.keys(errors.multiItems_tbStatus).length === 0 && Object.keys(errors.multiItems_cardissued).length === 0 && Object.keys(errors.multiItems_prevowner).length === 0 && Object.keys(errors.multiItems_export).length === 0 && Object.keys(errors.multiItems_calfReg).length === 0 && Object.keys(errors.multiItems_notes).length === 0 && Object.keys(errors.product_image).length === 0)
  //   {
  //     console.log("+++++++++++++++++0+k")
  //      addtoitem(e)
  //   }
  // }

  async function postItem(e) {
    if (!values.terms) {
      return setValues({
        ...values,
        ter: "Please accept the Terms & Conditions",
      });
    }

    toggleModal1();

    try {
      setLoading(true);
      setModalopen5(true);
      formData = new FormData();
      var breed = values.multiItems_breed[0],
        sex = values.multiItems_sex[0],
        agemin = TimetoDate(values.multiItems_dob[0]),
        agemax = TimetoDate(values.multiItems_dob[0]);

      //second page input data
      item.k.map((key) => {
        // formData.set(`product_image${key}`, values.product_image);
        formData.set(`manufacturer${key + 1}`, values.multiItems_calfReg[key]);
        formData.set(`finish${key + 1}`, values.multiItems_export[key]);
        formData.set(
          `custom_field_8${key + 1}`,
          values.multiItems_tbStatus[key]
        );
        formData.append(
          `contenthead1${key + 1}`,
          values.multiItems_breed[key].join(",")
        ); //breed as contenthead1
        formData.append(
          `contenthead4${key + 1}`,
          new Date(values.multiItems_cardissued[key])
        ); //testdate as contenthead4
        formData.append(
          `contenthead5${key + 1}`,
          values.multiItems_sex[key].join(",")
        ); //sex as contenthead5
        formData.append(`weight${key + 1}`, values.multiItems_weight[key]);

        if (breed !== values.multiItems_breed[key]) {
          breed = "m";
        }
        if (sex !== values.multiItems_sex[key]) {
          sex = "m";
        }
        if (parseInt(agemin) > TimetoDate(values.multiItems_dob[key])) {
          agemin = TimetoDate(values.multiItems_dob[key]);
        }
        if (parseInt(agemax) < TimetoDate(values.multiItems_dob[key])) {
          agemax = TimetoDate(values.multiItems_dob[key]);
        }
        formData.append(`contenthead2${key + 1}`, values.multiItems_dob[key]);
        formData.append(`tags${key + 1}`, values.multiItems_tagnumber[key]);
        formData.append(`auctionnotes${key + 1}`, values.multiItems_notes[key]);
        formData.append(
          `inspectiontime${key + 1}`,
          values.multiItems_testdate[key]
        );
        formData.append(
          `height${key + 1}`,
          values.multiItems_prevowner[key]
            ? values.multiItems_prevowner[key]
            : 0
        ); //prev owners as height
      });
      //below three fileds for search page display
      formData.append("contenthead2", agemin + "-" + agemax);
      formData.append(
        "contenthead5",
        sex == "m" ? "Mixed" : values.multiItems_sex[0]
      );
      formData.append(
        "contenthead1",
        breed == "m" ? "Mixed" : values.multiItems_breed[0]
      );

      formData.append("protitle", data[0].multiItems_title);
      formData.append("user_id", JwtDecode(isAuthenticated()).id);
      formData.append("description", data[0].multiItems_description);
      formData.append("declaration", data[0].multiItems_declaration);
      formData.append("category_id", data[0].multiItems_section); //section as category id
      formData.append("subcategory", data[0].multiItems_type); //type as subcategory

      var multiItems_weighing_method = data[0].multiItems_weighing_method || "";
      if (data[0].sale_method == "Not Sold by Weight") {
        multiItems_weighing_method = "Not Sold by Weight";
      }
      formData.append("contenthead3", multiItems_weighing_method); //weighing method as content_head3
      //tagnumbers as tags
      //cardissue date as inspectiontime

      formData.append("buyerpremium", values.multiItems_premiums); //premiums as buyerpremium
      formData.append(
        "length",
        data[0].multiItems_pref_times === "yes" ? 1 : 0
      );
      //truck turn as length

      const concatenatedValueString = data[0].multiItems_assurance
        .map((item) => item.value)
        .join("");
      const concatenatedValueNumber = Number(concatenatedValueString);
      formData.append("width", concatenatedValueNumber); //pref times as width
      formData.append(
        "extra_fields",
        `{"qa_farm": ${JSON.stringify(data[0].multiItems_qafarm)}}`
      );

      // formData.append("product_videos", data[0].product_videos);
      formData.append("product_video_s3_name", data[0].product_videos.s3_name);
      formData.append("auction", data[0].multiItems_auction_checked ? 1 : 0);
      formData.append("buynow", data[0].multiItems_buynow_checked ? 1 : 0);
      formData.append(
        "retailer_link",
        data[0].multiItems_estimation_avg
          ? data[0].multiItems_estimation_avg
          : 0
      );
      formData.append(
        "sprice",
        data[0].multiItems_startprice ? data[0].multiItems_startprice : 0
      );
      formData.append(
        "bprice",
        data[0].multiItems_buynowprice ? data[0].multiItems_buynowprice : 0
      );
      formData.append(
        "modelnumber",
        data[0].multiItems_resprice_checked === "private" ? 1 : 0
      );
      formData.append(
        "rprice",
        data[0].multiItems_resprice
          ? data[0].multiItems_resprice
          : data[0].multiItems_startprice
          ? data[0].multiItems_startprice
          : 0
      );

      formData.append(
        "localpickup",
        data[0].multiItems_pickup_location === "different_address" ? 1 : 0
      ); //localpickup location as location

      formData.append(
        "city",
        data[0].multiItems_pickup_location === "different_address"
          ? data[0].multiItems_town
          : data[0].present_town
      ); //town as city
      //Location need to be mapped from frontend

      var state =
        data[0].multiItems_pickup_location === "different_address"
          ? data[0].multiItems_county
          : data[0].present_county;

      if (state == "Galway" || state == "Mayo") var province = "Connaught 1";
      else if (state == "Leitrim" || state == "Roscommon" || state == "Sligo")
        var province = "Connaught 2";
      else if (
        state == "Carlow" ||
        state == "Kildare" ||
        state == "Kilkenny" ||
        state == "Laois" ||
        state == "Wexford" ||
        state == "Wicklow" ||
        state == "Tipperary" ||
        state == "Waterford"
      )
        var province = "South East";
      else if (
        state == "Dublin" ||
        state == "Longford" ||
        state == "Louth" ||
        state == "Meath" ||
        state == "Offaly" ||
        state == "Westmeath"
      )
        var province = "Leinster North";
      else if (
        state == "Clare" ||
        state == "Limerick" ||
        state == "Tipperary" ||
        state == "Waterford"
      )
        var province = "Munster 2";
      else if (state == "Cork" || state == "Kerry") var province = "Munster 1";
      else if (
        state == "Antrim" ||
        state == "Armagh" ||
        state == "Derry" ||
        state == "Down" ||
        state == "Fermanagh" ||
        state == "Tyrone"
      )
        var province = "Ulster 2";
      else if (state == "Monaghan" || state == "Cavan" || state == "Donegal")
        var province = "Ulster 1";

      formData.append(
        "state",
        data[0].multiItems_pickup_location === "different_address"
          ? data[0].multiItems_county
          : data[0].present_county
      ); //county as state

      formData.append("location", province);

      formData.append(
        "country",
        data[0].multiItems_pickup_location === "different_address"
          ? data[0].multiItems_country
          : data[0].present_country
      ); //Sountry name as location
      formData.append(
        "sell_location",
        data[0].multiItems_pickup_location === "different_address"
          ? data[0].multiItems_eircode
          : data[0].present_eircode
      ); //eircode as sell_location
      formData.append(
        "contenthead6",
        data[0].multiItems_pickup_location === "different_address"
          ? data[0].multiItems_bldgname
          : data[0].present_bldgname
      ); //bldg name as contenthead6
      formData.append(
        "custom_field_1",
        data[0].multiItems_pickup_location === "different_address"
          ? data[0].multiItems_stname
          : data[0].present_stname
      ); //street name as custom_field_1
      // formData.append("weight", "8000KG"); //street name as custom_field_1
      // formData.append("custom_field_2", "ANother 8000KG");
      formData.append("manufacturer", values.multiItem_calfReg);
      formData.append("finish", values.multiItems_export);
      formData.append("custom_field_8", values.multiItems_tbStatus); //street name as custom_field_1
      // formData.append("startdate", data[0].start_date);
      // formData.append("enddate", data[0].end_date);
      formData.append("product_status", "open");
      formData.append("multiple", "1");
      formData.append("sublotcount", key + 1);
      formData.append("no_need_videos", 0);
      formData.append(
        "icbf_number",
        props?.history?.location?.state?.val?.icbf_number
          ? props.history.location.state.val.icbf_number
          : 0
      );

      // Important! Image files need to be appended to the end of the form data to avoid upload issues!
      data[0].product_image.map((image) => {
        formData.append("product_image[]", image);
      });
      item.k.map((key2) => {
        values.product_image[key2].map((image) => {
          formData.append(`product_image${key2 + 1}[]`, image);
        });
      });

      const res = await request(
        "post",
        "/sellerpostprojectlivemultiple",
        formData,
        false,
        true
      );

      setLoading(false);
      setModalopen5(false);
      if (res.data.status && res.data.status != "false") {
        await props.history.push("/my-auctions");
        return Toast.fire({
          type: "success",
          title: `Your Lot has been submitted. Thank you. We wish you good luck with your sale.`,
        });
      } else if (
        res.data.status &&
        res.data.status == "false" &&
        res.data.message &&
        res.data.message != "Failure"
      ) {
        Toast.fire({
          type: "error",
          title: res.data.message,
        });
      } else {
        SwalAlert.fire({
          type: "error",
          title: `Important!\nIt’s not you, it’s us. We appear to be having some issues uploading your lot.\nPlease try again, by hitting “Submit”.\nIf this issue persists, contact us at 056 780 1699 or support@farmfayre.com.\nYour satisfaction is our number one priority.\nThank you.`,
        });
      }
      // console.log("selerupdateproject");
    } catch (error) {
      setLoading(false);
      console.log(error);
      SwalAlert.fire({
        type: "error",
        title: `Important!\nIt’s not you, it’s us. We appear to be having some issues uploading your lot.\nPlease try again, by hitting “Submit”.\nIf this issue persists, contact us at 056 780 1699 or support@farmfayre.com.\nYour satisfaction is our number one priority.\nThank you.`,
      });
    }
  }

  // const onClickImages = (e) => {
  //   Object.keys(e.target.files).map((value, key) => {
  //     console.log(e.target.files[key]);
  //     setValues({
  //       ...values,
  //       product_image: [...values.product_image, e.target.files[key]],
  //     });
  //     /*   setValues(prevState => ({ ...prevState, product_image: [...prevState.product_image, e.target.files[key]]}));   */
  //   });
  //   console.log(e.target.files);
  // };
  const [options] = useState({
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  });

  const onClickImages = (e, id) => {
    Object.keys(e.target.files).map(async (value, key) => {
      var img = await imageCompression(e.target.files[key], options);
      var formData = new FormData();
      formData.append("product_images", img);
      const res = await request("post", "/image_upload", formData, false, true);

      if (res.data && res.data.arrayimages) {
        console.log("images -- ", res.data.arrayimages);
        res.data.arrayimages.map((image) => {
          values.product_image[id].splice(0, 0, [image]);
          setValues({ ...values });
        });
      }
    });
  };

  const preve = () => {
    var data = {
      values: props.history.location.state.val,
      page2: values,
      arr: item.k,
    };

    props.history.push({ pathname: "/multiple-items-step1", state: data });
  };

  const matches = useMediaQuery("(max-width:575px)");

  return (
    <Layout>
      <div className="main-container">
        <div className="pp-back">
          <img src="/images/listingSingle.jpg" alt="" />
        </div>
        <div className="pl-white-box">
          <h2 className="pl-head">POST A LISTING</h2>
          <div className="step-wrapper multi-items-step">
            <div className="clearfix step step1 completed">
              <div className="step-circle">01</div>
              <div className="step-label">LOT DETAILS</div>
            </div>
            <div className="step step2 active">
              <div className="step-circle">02</div>
              <div className="step-label">ITEM DETAILS</div>
            </div>
          </div>
          {item &&
            item.k.map((val) => (
              <>
                {(parseInt(val) > 0 &&
                  props.history.location.state.val.multiItems_section !=
                    "Pedigree") ||
                (parseInt(val) > 0 &&
                  props.history.location.state.val.multiItems_section ==
                    "Pedigree") ? (
                  <div className="row">
                    <div className="col-12 customContainer">
                      <div className="addItem">
                        <Button onClick={(e) => removeitemchange(e, val)}>
                          <span className="material-icons">delete</span>
                          DELETE ITEM
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="pl-form">
                  <h4 className="form-ghead">Item Details</h4>
                  {/* <div className="row">
            <div className="col-md-6 form-group">
              <CustomInput
                id="item-title"
                label="Item title"
                placeholder="Enter your item title"
                tooltiptitle="Title of the item"
                name="item_title"
                value={values.item_title}
                onChange = {handleChange}
              />
            </div>
          </div> */}
                  {/* <div className="row">
            <div className="col-md-12 form-group">
              <CustomInput
                id="description"
                label="Description"
                placeholder="Enter a brief description"
                multiline
                rows={4}
                tooltiptitle="Description of the item"
                name="item_description"
                value={values.item_description}
                onChange = {handleChange}
              />
            </div>
          </div> */}
                  {/* {console.log("====================", val)} */}
                  <div className="row">
                    <div
                      className={`col-sm-6 col-md-3 form-group ${
                        matches && "col-6"
                      }`}
                    >
                      <CustomInput
                        id={val}
                        label="Tag number"
                        placeholder="Enter Tag Number"
                        //con={true}
                        tooltiptitle="Tag number assigned to the animal"
                        name="multiItems_tagnumber"
                        value={values.multiItems_tagnumber[val]}
                        onChange={handleChange}
                        error={
                          state && !values.multiItems_tagnumber[val]
                            ? true
                            : false
                        }
                        helperText={
                          values.multiItems_tagnumber[val] === undefined ||
                          !values.multiItems_tagnumber[val]
                            ? errors.multiItems_tagnumber[val]
                            : ""
                        }
                      />
                    </div>
                    <div
                      className={`col-sm-6 col-md-3 form-group ${
                        matches && "col-6"
                      }`}
                    >
                      <SearchOptions
                        id={val}
                        label="Breed"
                        selection={Breed}
                        onchange={handleChange1(val)}
                        name="multiItems_breed"
                        value={values.multiItems_breed[val] || []}
                      />
                      {/* <CustomSelect
                        id={val}
                        label="Breed"
                        tooltiptitle="Breed of the animal"
                        name="multiItems_breed"
                        value={values.multiItems_breed[val]}
                        selectMenuHeader={"Select Breed"}
                        selectMenu={Breed}
                        error={
                          state && !values.multiItems_breed[val] ? true : false
                        }
                        // helperText={state?errors.multiItems_breed:""}
                        pc_onchange={handleChange}
                      ></CustomSelect>
                      {state && (
                        <FormHelperText error>
                          {values.multiItems_breed[val] == undefined ||
                          !values.multiItems_breed[val]
                            ? errors.multiItems_breed[val]
                            : ""}
                        </FormHelperText>
                      )} */}
                    </div>
                    <div
                      className={`col-sm-6 col-md-3 form-group ${
                        matches && "col-6"
                      }`}
                    >
                      {/* <CustomSelect
                        id={val}
                        select
                        label="Sex"
                        tooltiptitle="Gender type of the animal"
                        name="multiItems_sex"
                        value={values.multiItems_sex[val]}
                        selectMenuHeader={"Select Sex"}
                        selectMenu={Sex}
                        error={
                          state && !values.multiItems_sex[val] ? true : false
                        }
                        pc_onchange={handleChange}
                      ></CustomSelect>
                      {state && (
                        <FormHelperText error>
                          {!values.multiItems_sex[val] ||
                          values.multiItems_sex[val] === undefined
                            ? errors.multiItems_sex[val]
                            : ""}
                        </FormHelperText>
                      )} */}
                      <SearchOptions
                        id={val}
                        label="Sex"
                        selection={Sex}
                        onchange={handleChange1(val)}
                        name="multiItems_sex"
                        value={values.multiItems_sex[val] || []}
                      />
                    </div>
                    <div
                      className={`col-sm-6 col-md-3 form-group ${
                        matches && "col-6"
                      }`}
                    >
                      {/*               <CustomInput
                id="dob"
                label="DOB"
                placeholder="Select date of birth"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span className="material-icons">calendar_today</span>
                    </InputAdornment>
                  ),
                }}
                tooltiptitle="Animal's date of birth"
              /> */}
                      <DatePicker
                        id={val}
                        key="dob"
                        label="DOB"
                        name="multiItems_dob"
                        disableFuture={true}
                        min={new Date(data[0].multiItems_first_dob)}
                        max={new Date(data[0].multiItems_first_dob1)}
                        value={values.multiItems_dob[val]}
                        placeholder="Select date of birth"
                        onchange={handleDateChange}
                        tooltiptitle="Animal's date of birth"
                        error={
                          state ? !values.multiItems_dob[val] && true : false
                        }
                        helperText={
                          !values.multiItems_dob[val] ||
                          values.multiItems_dob[val] === undefined
                            ? errors.multiItems_dob[val]
                            : ""
                        }
                      />
                    </div>

                    <div
                      className={`col-sm-6 col-md-3 form-group ${
                        matches && "col-6"
                      }`}
                    >
                      <CustomInput
                        id={val}
                        label="Weight"
                        con={true}
                        placeholder="Enter weight in kgs"
                        tooltiptitle="Gender type of the animal"
                        name="multiItems_weight"
                        value={values.multiItems_weight[val]}
                        onChange={handleChange}
                        error={
                          data[0].sale_method == "Not Sold by Weight"
                            ? false
                            : state
                            ? !values.multiItems_weight[val] && true
                            : false
                        }
                        helperText={
                          data[0].sale_method == "Not Sold by Weight"
                            ? ""
                            : !values.multiItems_weight[val] ||
                              values.multiItems_weight[val] === undefined
                            ? errors.multiItems_weight[val]
                            : ""
                        }
                      />
                    </div>
                    {/* <div className="col-sm-6 col-md-3 form-group">
                <CustomSelect
                  id="weigh-method"
                  label="Weighing method"
                  tooltiptitle="Method to caluclate the weigh of the animal"
                  name="item_weighing_method"
                  value={values.multiItems_weighing_method}
                  pc_onchange={handleChange}
                >
                  <option key="" value="">
                    Select Weighing method
                  </option>

                  <option key="method1" value="method1">
                    method 1
                  </option>

                  <option key="method2" value="method2">
                    method 2
                  </option>
                </CustomSelect>
                {state && (
                  <FormHelperText error>{errors.multiItems_sex}</FormHelperText>
                )}
              </div> */}

                    <div
                      className={`col-sm-6 col-md-3 form-group ${
                        matches && "col-6"
                      }`}
                    >
                      <CustomSelect
                        id={val}
                        key="export"
                        label="Export"
                        name="multiItems_export"
                        value={values.multiItems_export[val]}
                        tooltiptitle="Export"
                        selectMenu={["Yes", "No"]}
                        selectMenuHeader={"Select export"}
                        pc_onchange={handleChange}
                        error={
                          state && !values.multiItems_export[val] ? true : false
                        }
                        //helperText={values.multiItems_export[val]===undefined || !values.multiItems_export[val]?errors.multiItems_export[val]:""}
                      ></CustomSelect>
                      {state && (
                        <FormHelperText error>
                          {!values.multiItems_export[val] ||
                          values.multiItems_export[val] === undefined
                            ? errors.multiItems_export[val]
                            : ""}
                        </FormHelperText>
                      )}
                    </div>

                    <div
                      className={`col-sm-6 col-md-3 form-group ${
                        matches && "col-6"
                      }`}
                    >
                      <CustomSelect
                        id={val}
                        key="calfReg"
                        label="Calf Reg"
                        name="multiItems_calfReg"
                        selectMenu={["Yes", "No"]}
                        selectMenuHeader={"Select Calf Reg"}
                        pc_onchange={handleChange}
                        value={values.multiItems_calfReg[val]}
                        tooltiptitle="Select if calf is registered"
                        error={
                          state && !values.multiItems_calfReg[val]
                            ? true
                            : false
                        }
                      ></CustomSelect>
                      {state && (
                        <FormHelperText error>
                          {!values.multiItems_calfReg[val] ||
                          values.multiItems_calfReg[val] === undefined
                            ? errors.multiItems_calfReg[val]
                            : ""}
                        </FormHelperText>
                      )}
                    </div>

                    <div
                      className={`col-sm-6 col-md-3 form-group ${
                        matches && "col-6"
                      }`}
                    >
                      {/*         <CustomInput
                id="test-date"
                label="Last test date"
                placeholder="Select last test date"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span className="material-icons">calendar_today</span>
                    </InputAdornment>
                  ),
                }}
                tooltiptitle="Date of animal tested"
              /> */}
                      <DatePicker
                        id={val}
                        key="test-date"
                        label="Date into herd"
                        placeholder="Select date into herd"
                        name="multiItems_cardissued"
                        disableFuture={true}
                        min={new Date(data[0].multiItems_first_cardissued)}
                        max={new Date(data[0].multiItems_first_cardissued1)}
                        value={values.multiItems_cardissued[val]}
                        onchange={handleDateChange}
                        tooltiptitle="Date animal was tested"
                        error={
                          state
                            ? !values.multiItems_cardissued[val] && true
                            : false
                        }
                        helperText={
                          values.multiItems_cardissued[val] === undefined &&
                          !values.multiItems_cardissued[val]
                            ? errors.multiItems_cardissued[val]
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className={`col-sm-6 col-md-3 form-group ${
                        matches && "col-6"
                      }`}
                    >
                      {/*         <CustomSelect
                id="previous-owners"
                label="No. of previous owners"
                tooltiptitle="No of previous owners"
              >
                <option key="" value="">
                  No of previous owners
                </option>
              </CustomSelect> */}

                      <CustomSelect
                        id={val}
                        label="Movement"
                        tooltiptitle="No of previous movement"
                        name="multiItems_prevowner"
                        value={values.multiItems_prevowner[val]}
                        selectMenuHeader={"Select Pevious movement"}
                        selectMenu={Owners}
                        error={
                          state && !values.multiItems_prevowner[val]
                            ? true
                            : false
                        }
                        // helperText={state?errors.multiItems_breed:""}
                        pc_onchange={handleChange}
                      ></CustomSelect>
                      {state && (
                        <FormHelperText error>
                          {!values.multiItems_prevowner[val] ||
                          values.multiItems_prevowner[val] === undefined
                            ? errors.multiItems_prevowner[val]
                            : ""}
                        </FormHelperText>
                      )}
                    </div>

                    {/* <div className="col-sm-6 col-md-3  form-group">
                <CustomInput
                  id="premiums"
                  label="Premiums collected"
                  placeholder="Premiums collected"
                  tooltiptitle="Premiums collected"
                  name="item_premiums"
                  value={values.mutiItems_premiums}
                  onChange={handleChange}
                  error={state ? !values.multiItems_premiums && true : false}
                  helperText={state && errors.multiItems_premiums}
                />
              </div> */}

                    <div
                      className={`col-sm-6 col-md-3 form-group ${
                        matches && "col-6"
                      }`}
                    >
                      {/*           <CustomInput
                id="issued-date"
                label="Date card issued"
                placeholder="Select date of issue"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span className="material-icons">calendar_today</span>
                    </InputAdornment>
                  ),
                }}
                tooltiptitle="Date of animal issued"
              /> */}
                      <DatePicker
                        id={val}
                        key="issued-date"
                        label="TB test date"
                        disableFuture={true}
                        min={values.multiItems_testdate[val]}
                        placeholder="Select TB test date"
                        name="multiItems_testdate"
                        value={values.multiItems_testdate[val]}
                        onchange={handleDateChange}
                        tooltiptitle="Date card was issued"
                        error={
                          state
                            ? !values.multiItems_testdate[val] && true
                            : false
                        }
                        helperText={
                          values.multiItems_testdate[val] === undefined &&
                          !values.multiItems_testdate[val]
                            ? errors.multiItems_testdate[val]
                            : ""
                        }
                      />
                    </div>

                    {/* <div className="col-sm-6 col-md-3 form-group">
                    <CustomSelect
                      id={val}
                      key="tbStatus"
                      label="TB Status"
                      name="multiItems_tbStatus"
                      selectMenu={["Positive", "Clear"]}
                      value={values.multiItems_tbStatus[val]}
                      tooltiptitle="TB Status"
                      pc_onchange={handleChange}
                      error={
                        state && !values.multiItems_tbStatus[val] ? true : false
                      }
                      selectMenuHeader={"Select TB Status"}
                    ></CustomSelect>
                    {state && (
                      <FormHelperText error>
                        {!values.multiItems_tbStatus[val] ||
                        values.multiItems_tbStatus[val] === undefined
                          ? errors.multiItems_tbStatus[val]
                          : ""}
                      </FormHelperText>
                    )}
                  </div> */}
                  </div>

                  <div className="row">
                    <div className="col-md-12 form-group notesCls">
                      <CustomInput
                        id={val}
                        label="Notes"
                        placeholder="Enter notes"
                        fullWidth
                        multiline
                        rows={4}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        tooltiptitle="Miscellaneous notes of an item"
                        name="multiItems_notes"
                        value={values.multiItems_notes[val]}
                        onChange={handleChange}
                        // error={state ? !values.multiItems_notes[val] && true : false}
                        // helperText={state && errors.multiItems_notes[val]}
                      />
                    </div>
                    <div className="col-12 form-group">
                      <Upload
                        src="/images/insert-photo.png"
                        header="UPLOAD IMAGE"
                        subheader="You can enter multiple image files"
                        id={val}
                        title="Images displayed under each item"
                        accept="image/*"
                        onchange={onClickImages}
                        multiple={true}
                      />
                      {state && values.product_image.length > 0 && (
                        <FormHelperText error>
                          {!values.product_image[val]?.length
                            ? errors.product_image[val]
                            : ""}
                        </FormHelperText>
                      )}
                      <div className="row">
                        {values.product_image[val] !== undefined &&
                          values.product_image[val].map((m) =>
                            m.map((j, k) => (
                              <div
                                className="col-md-3 productPostThumbnails"
                                key={k}
                              >
                                <label
                                  onClick={() => removeImage(j, val)}
                                  name={k}
                                  className="pp-edit material-icons"
                                >
                                  close
                                </label>
                                <img
                                  src={AvatarURL + j}
                                  className="pro-img rounded img-fluid"
                                />
                              </div>
                            ))
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          <div className="row">
            <div className="col-12 customContainer">
              <div className="addItem">
                <Button onClick={additemchange}>
                  <span className="material-icons">add_circle_outline</span>ADD
                  ANOTHER ITEM
                </Button>
              </div>
            </div>
          </div>
          <div className="btns-wrap">
            <div className="pl-btn trans-btn pt-4">
              <TransButton onsubmit={preve} label="PREVIOUS">
                <span className="material-icons">arrow_backward</span>
              </TransButton>
            </div>
            <div className="pl-btn pt-4">
              <PrimaryButton
                // value={values}
                onsubmit={handleSubmit}
                label="SUBMIT"
              ></PrimaryButton>
            </div>
          </div>
        </div>

        <FFModal
          open={modalopen1}
          handleClose={toggleModal1}
          modaltitle="Seller's Declaration"
        >
          <div className="seller-note" style={{ fontWeight: "500" }}>
            {ReactHtmlParser(content)}

            <div className="jc-center">
              <CheckBox
                name="terms"
                onchange={handleChange}
                value={values.terms}
                label="I Declare the above to be true and correct to the best of my knowledge, and shall be responsible as per the Terms & Conditions accordingly."
              />
            </div>

            <div className="pl-btns">
              <FormHelperText error>
                {values.ter ? values.ter : ""}
              </FormHelperText>

              {loading ? (
                <CircularProgress />
              ) : (
                <PrimaryButton
                  label={"POST MY LISTING"}
                  btnSize={"smallsize"}
                  onsubmit={postItem}
                />
              )}
            </div>
          </div>
        </FFModal>
        <FFModal
          open={modalopen2}
          handleClose={toggleModal2}
          modaltitle="Input Breed"
        >
          <div style={{ fontWeight: "500" }} className="enterBreed">
            <textarea
              name="collection_breed"
              id={v}
              value={values.collection_breed}
              onKeyPress={TextOnly}
              onChange={breedchage}
              placeholder="Enter Breed here..."
            ></textarea>
            {/* <Button onClick={callcommand}>submit</Button> */}
            <div className="pl-btn pt-4">
              <PrimaryButton
                label={"submit"}
                onsubmit={callcommand}
                btnSize={"9"}
              ></PrimaryButton>
            </div>
          </div>
        </FFModal>

        <FFModal
          open={modalopen3}
          handleClose={toggleModal3}
          modaltitle="Breed # 1"
        >
          <div style={{ fontWeight: "500" }}>
            {/* {console.log("cross",values.collection_breed)}
          <SearchOptions
              label={<span style={{backgroundColor:"white"}}>&nbsp;CrossBreed&nbsp;</span>}
              selection={Crossbreed}
              onchange={breedchage}
              name="collection_breed"
              value={values.collection_breed}
            /> */}

            <div className="col-12">
              <CustomSelect
                label="Breed # 1"
                tooltiptitle="Breed # 1 of the animal"
                name="collection_breed"
                value={values.collection_breed}
                selectMenuHeader={"Select Breed # 1"}
                selectMenu={Crossbreed}
                // error={state && !values.multiItems_breed[val]?true:false}
                // helperText={state?errors.multiItems_breed:""}
                pc_onchange={breedchage}
              ></CustomSelect>

              <FormHelperText error>
                {!values.collection_breed ? values.bre : ""}
              </FormHelperText>
            </div>

            {/* <textarea name="multiItems_breed" id={v} value={values.multiItems_breed[v]} onKeyPress={specialcharacter} onChange={handleChange}>Enter Breed here...</textarea> */}
            {/* <Button onClick={callcommand}>submit</Button> */}
            <div className="pl-btn pt-4">
              <PrimaryButton
                label={"submit"}
                onsubmit={callcommand1}
                btnSize={"9"}
              ></PrimaryButton>
            </div>
          </div>
        </FFModal>

        <FFModal
          open={modalopen4}
          handleClose={toggleModal4}
          modaltitle="Breed # 2"
        >
          <div style={{ fontWeight: "500" }}>
            {/* {console.log("cross",values.collection_breed)}
          <SearchOptions
              label={<span style={{backgroundColor:"white"}}>&nbsp;CrossBreed&nbsp;</span>}
              selection={Crossbreed}
              onchange={breedchage}
              name="collection_breed"
              value={values.collection_breed}
            /> */}

            <div className="col-12">
              <CustomSelect
                label="Breed # 2"
                tooltiptitle="Breed # 2 of the animal"
                name="collection_breed"
                value={values.collection_breed}
                selectMenuHeader={"Select Breed # 2"}
                selectMenu={Crossbreed}
                // error={state && !values.multiItems_breed[val]?true:false}
                // helperText={state?errors.multiItems_breed:""}
                pc_onchange={breedchage}
              ></CustomSelect>

              <FormHelperText error>
                {!values.collection_breed ? values.bre : ""}
              </FormHelperText>
            </div>

            {/* <textarea name="multiItems_breed" id={v} value={values.multiItems_breed[v]} onKeyPress={specialcharacter} onChange={handleChange}>Enter Breed here...</textarea> */}
            {/* <Button onClick={callcommand}>submit</Button> */}
            <div className="pl-btn pt-4">
              <PrimaryButton
                label={"submit"}
                onsubmit={finalsubmit}
                btnSize={"9"}
              ></PrimaryButton>
            </div>
          </div>
        </FFModal>

        <FFModal
          open={modalopen5}
          handleClose={toggleModal5}
          modaltitle="Message"
        >
          <div style={{ fontWeight: "500" }}>
            <p style={{ fontSize: "14px" }}>
              <b>
                Thank you for submitting your lot.<br></br>
                We're currently uploading your images and videos. This may take
                a moment so please do not update this page. <br></br>
                Thank you.
              </b>
            </p>
          </div>
        </FFModal>
      </div>
    </Layout>
  );
};

export default MultipleItemsStep2;
