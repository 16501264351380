import React, { useEffect, useRef, useState } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Modal from "@material-ui/core/Modal";
import { isAuthenticated } from "../../Utils/Authenticated";
import { request, requestGet } from "../../Utils/Request";
import { useHistory } from "react-router-dom";
import {
  eDate,
  sDate,
  TimetoDate,
  TimetoDay,
  SaleNumber,
  ItemNum,
} from "../../Utils/TimerProfile";
import Layout from "../../components/Layout";
import {
  AvatarURL,
  callNumber,
  IMAGE_URL,
  NumberOnly,
  callNumber1,
  Sentencecase,
  NewCounties,
  perkgamount,
  Quality_asurance,
} from "../../Utils/constant";
import CustomInput from "../../components/Inputs/borderedInput";
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import Drawer from "@material-ui/core/Drawer";
import MultiItem from "./MultiItem";
import Rating from "@material-ui/lab/Rating";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FFModal from "../../components/Modal";
import "./product.css";
import { addTimer } from "../../redux/action/UserAction";
import { Toast } from "../../Utils/Notifications";
import JwtDecode from "jwt-decode";
import { bidAddtime, realClosedUpdates, socket } from "../../Utils/Socket";
import Timer from "../../Utils/Timer";
import { connect } from "react-redux";
import Popover from "@material-ui/core/Popover";
import { Link } from "react-router-dom";
import Bidhistory from "./Bidhistory";
import Message from "../Message/Message";
import { Button, Tooltip, useMediaQuery } from "@material-ui/core";
import moment from "moment";
import FavoriteCheckbox from "../../components/FavouriteCheck/FavouriteCheckbox";
import { set } from "date-fns";
import { TvRounded, SelectAllRounded, FilterDrama } from "@material-ui/icons";
import { hi } from "date-fns/locale";
import { keys } from "@material-ui/core/styles/createBreakpoints";
import Reviews from "../../components/Reviews";
import PaymentCard from "../Checkout/PaymentCard";
import PaymentCardDeposit from "../Checkout/PaymentCardDeposit";
import { CountiesShortform } from "../../Utils/constant";

import {
  CardElement,
  useStripe,
  Elements,
  useElements,
  IbanElement,
} from "@stripe/react-stripe-js";
import CheckBox from "../../components/Inputs/CheckBox";
import { BreedshortNames, BreedshortNamesICBF } from "../../Utils/constant";
// import { useStripe, useElements} from '@stripe/react-stripe-js';

const ProductView = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [bidMax, setBidMax] = useState(false);
  // const stripe = useStripe();
  // console.log("stripe",stripe)
  // const elements = useElements();
  const [userData, setData] = useState({ aboutme: 0, regtype: 0 });
  const [buynowdisabled, setBuynowdisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [depositAmount, setDepositAmount] = useState(0);
  const [QATerm, setQATerm] = useState(false);
  const [watermarkImage, setWatermarkImage] = useState([]);
  const popClick = (event) => {
    if (!isAuthenticated() || localStorage.getItem("userRole") === "tier1") {
      setVisitorModalOpen(true);
    } else {
      if (mulitem.qa.includes("FeedLot Status") && QATerm == false) {
        setModalopen8(true);
      } else {
        setAnchorEl(event.currentTarget);
      }
    }
  };
  const matches = useMediaQuery("(max-width:600px)");

  const popClose = () => {
    setAnchorEl(null);
  };
  const popClick1 = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const popClose1 = () => {
    setAnchorE2(null);
  };

  const openPop = Boolean(anchorEl);
  const openPop1 = Boolean(anchorE2);
  const maxBid = openPop ? "max-bid-popover" : undefined;
  const oneClick = openPop1 ? "one-click-popover" : undefined;

  const history = useHistory();

  const initialState = {
    prodImages: [],
    proddetails: {},
    addamount: "",
    itemid: "",
    proid: "",
  };
  const [cards, setCards] = useState(false);
  const [features, setFeatures] = useState(initialState);
  const [s3_video, setS3video] = useState(true);
  const productRef = useRef("");

  const userRef = useRef(localStorage.userId);
  const [values, setValues] = useState({
    next_bid: "",
    bid_amount: "",
    bidplaced: false,
    init_amount: "",
  });
  const [values2, setValues2] = useState({
    haulier: "Loading...",
    haulier_amount: 0,
  });
  const [bidplaced, setBidplac] = useState(true);
  const [dateClosed, setDateClosed] = useState(null);

  const [state, setState] = useState({
    user: isAuthenticated() && JwtDecode(isAuthenticated()),
    bid_details: {},
    increment_amount: 1,
  });
  const [error, setError] = useState({
    bid: "",
  });
  var b,
    rprice = 0;
  const [bid_or_not, setbid_or_not] = useState(0);
  const [visitorModalOpen, setVisitorModalOpen] = useState(false);
  const [mulitem, setItem] = useState({
    breed: "",
    sex: "",
    wei: "",
    own: "",
    age: "",
    qa: "",
    len: "",
    days: "",
    agemi: "",
    agema: "",
    testdate: "",
    cr: "",
    ex: "",
    wm: "",
    qa_sd: "",
  });
  const images = [];
  const [bidApi, setBidApi] = useState({
    data: [],
    bid_count: "",
    current_price: "",
    bid_count: "",
    top_message: "",
    bid_or_not: 0,
  });

  const [sepaValue, setSepaValue] = useState({
    name: localStorage.userFirstName + " " + localStorage.userLastName,
    email: localStorage.userEmail,
    company_name: localStorage.userCompanyName,
  });
  // setMessage({
  //   ...message,
  //   from_id: state.user.id,
  //   to_id:"",
  //   project_id: props.match.params.id,
  //   curr
  // ent_id: state.user.id,
  // });
  const addchange = async (e) => {
    if (!isAuthenticated()) {
      setModelopen7(true);
      return false;
    }
    var { id, checked } = e.target;
    // console.log("+++++++", id, checked);

    if (checked) {
      const res = await request(
        "post",
        "/watchlist",
        { id: id, userid: JwtDecode(isAuthenticated()).id },
        false,
        true
      );

      // console.log("+++++",res)
      if ((res.data.success = "yes")) {
        Toast.fire({ type: "success", title: "Lot added to Favourite" });
      } else {
        Toast.fire({ type: "error", title: "Something went wrong" });
      }
    } else {
      const res = await request(
        "post",
        "/removeFwatchlist",
        { id: id, userid: JwtDecode(isAuthenticated()).id },
        false,
        true
      );

      // console.log("+++++",res)
      if ((res.data.success = "yes")) {
        Toast.fire({ type: "success", title: "Lot removed from Favourite" });
      } else {
        Toast.fire({ type: "error", title: "Something went wrong" });
      }
    }
  };

  const handlechange = async (e) => {
    // console.log("++++++++",e.which)
    // if ((e.which !== 8 && e.which !== 0 && e.which < 48 && e.which !==47) || e.which > 57)
    //          e.target.value=""

    if (
      !e.target.value ||
      Number(e.target.value.toString().split(",").join("")) <
        Number(values.next_bid)
    ) {
      setError({
        ...error,
        bid: `Your Bid Amount must be greater than or equal to €${callNumber(
          values.next_bid
        )}`,
      });
    } else {
      setError({ ...error, bid: "" });
    }
    // console.log("+++++++++==",errors.bid)
    setValues({ ...values, bid_amount: e.target.value });
    if (e.target.name == "Quick") {
      if (userData.aboutme !== "1") {
        setModelopen4(true);
      } else {
        setBidMax(false);
        onsubmitbid(e);
      }
    }
  };

  const [savedCard, setSavedCard] = useState([]);
  const [getSaved, setGetSaved] = useState("");
  const getAllCards = async (e) => {
    const res = await request(
      "post",
      "/viewAllCard",
      { mode: process.env.REACT_APP_PAYMENT_MODE },
      false,
      true
    );
    if (res.data && res.data.success) {
      setSavedCard(res.data.data);
    }
  };
  const selectSavedCard = (e) => {
    setGetSaved(e.target.value);
  };

  const onsubmitbid = async (e, type = "normal") => {
    // e.preventDefault()

    //   console.log("3333333333")
    //   const payload = await stripe.confirmCardPayment(
    //     'pi_1HjW0YDnTEnzFpTDyX0J58BT_secret_xFTJWhqTWvIYkAvyP0s2c1HnX',
    //     {
    //     payment_method:"card_1HcF1WERipdilyX4lhAETbL8",
    //     },
    //     );
    // console.log("22222222")
    // if (payload.error) {
    //   console.log("[error]", payload.error);
    // }else {tion
    //   console.log("[PaymentIntent]", payload.paymentIntent);
    // }
    //  values.bid_amount.toString().split(",").join("");
    //  setValues({...values})
    toggleModal2();
    try {
      if (!isAuthenticated()) {
        Toast.fire({ title: "Please login to continue!", icon: "info" });
        return props.history.push({
          pathname: "/login",
          state: {
            path: props.history.location.pathname,
            id: props.history.location.state,
          },
        });
      }

      if (String(props.timer) === "true") {
        return Toast.fire({
          title: "Wait until sale start!",
          icon: "warning",
        });
      }

      if (userData.regtype && userData.regtype == "1") {
        confirmbidplace(e, type);
      } else {
        const deposit = await getDeposit();
        console.log("getDeposit===========", deposit);
        if (!deposit) {
          setModopen1(true);
        } else {
          confirmbidplace(e, type);
        }
      }
      //else if (parseInt(values.bid_amount.toString().split(",").join("")) > 10000) {
      //   setModopen2(true);
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange1 = () => {
    localStorage.setItem(`bidCheck-${props.match.params.id}`, true);
    setModalopen8(false);
    return null;
  };
  const getDeposit = async () => {
    const res = await request(
      "post",
      "/getUserDeposit",
      {
        project_id: features.proddetails.id,
      },
      false,
      true
    );
    console.log("getUserDeposit=======", res.data);
    if (res.data.success === true) {
      let deposit_amount = parseFloat(res.data.deposit.credit_amount || 0);
      let total_bid_amount = parseFloat(res.data.deposit.bid_amount || 0);
      var bid_amount = parseFloat(
        values.bid_amount.toString().split(",").join("")
      );
      bid_amount = parseFloat(bid_amount * (mulitem.len - 1));
      console.log(
        "item count: ",
        mulitem.len - 1,
        "current_bid_amount: ",
        bid_amount,
        "total_bid_amount: ",
        total_bid_amount,
        "deposit_amount * 10: ",
        deposit_amount * 10
      );
      setDepositAmount(deposit_amount);
      if (deposit_amount * 10 >= bid_amount + total_bid_amount) {
        return true;
      }
    }
    return false;
  };

  const addDepositCard = async () => {
    try {
      var deposit_amount = parseFloat(
        values.init_amount.toString().split(",").join("")
      );

      // saved card
      var cardId = "";
      if (!getSaved) {
        console.log("++++++");
        if (savedCard.length > 0) {
          setGetSaved(0);
          cardId = savedCard[0].id;
        } else {
          return Toast.fire({
            title: "Please select atleast one card!",
            type: "warning",
          });
        }
      } else {
        cardId = savedCard[getSaved].id;
      }

      setPaymentLoading(true);

      // // Use your card Element with other Stripe.js APIs
      // const cardElement = elements.getElement(CardElement);
      // const { error2, paymentMethod } = await stripe.createPaymentMethod({
      //   type: "card",
      //   card: cardElement,
      // });

      // if (error2 || !paymentMethod) {
      //   console.log("[error]", error2);
      //   setPaymentLoading(false);
      //   return Toast.fire({
      //     type: "error",
      //     title: "Please enter a vaild card.",
      //   });
      // } else {
      //   console.log("[PaymentMethod]", paymentMethod);
      // }

      var trustap_params = {
        user_id: JwtDecode(isAuthenticated()).id,
        amount: deposit_amount,
        payment_method: "card",
        mode: process.env.REACT_APP_PAYMENT_MODE,
        cardId: cardId,
        currency: "eur",
      };
      console.log("+++++++++++++++++TrustapDeposit_params", trustap_params);

      const res = await request(
        "post",
        "/TrustapDeposit",
        trustap_params,
        false,
        true
      );
      console.log("+++++++++++++++++TrustapDeposit res", res.data);
      if (res.data.success) {
        // if (res.data.results.transaction_id && res.data.results.client_secret) {
        //   const { paymentIntent, error } = await stripe.confirmCardPayment(
        //     res.data.results.client_secret,
        //     {
        //       payment_method: paymentMethod.id,
        //     }
        //   );
        //   if (error || !paymentIntent) {
        //     console.log("confirmCardPayment+++++++++++++++++e", error);
        //     setPaymentLoading(false);
        //     return Toast.fire({ type: "error", title: error });
        //   } else {
        //     console.log("confirmCardPayment+++++++++++++++++s", paymentIntent);
        //     //trustap_params['transid'] = paymentIntent.id
        //     trustap_params["transaction_id"] = res.data.results.transaction_id;
        //   }
        // } else {
        //   setPaymentLoading(false);
        //   return Toast.fire({
        //     type: "error",
        //     title: "Trustap transanction creation error",
        //   });
        // }

        trustap_params["transaction_id"] = res.data.results.transaction_id;

        console.log("updateUserDeposit======trustap_params", trustap_params);
        const res2 = await request(
          "post",
          "/updateUserDeposit",
          trustap_params,
          false,
          true
        );
        console.log("updateUserDeposit====== res", res2.data);
        if (res2.data.success) {
          setPaymentLoading(false);
          toggleMod1();
          onsubmitbid(null, "");
          //return Toast.fire({ title: 'Paid Successfully!', type: 'success' })
        } else {
          setPaymentLoading(false);
          return Toast.fire({ type: "error", title: "Deposit update error" });
        }
      } else {
        setPaymentLoading(false);
        return Toast.fire({ type: "error", title: res.data.message });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addDepositSEPA = async (name, email) => {
    console.log("submitSEPA", name, email);
    try {
      var deposit_amount = parseFloat(
        values.init_amount.toString().split(",").join("")
      );

      setPaymentLoading(true);

      // Use your Iban Element with other Stripe.js APIs
      const iban = elements.getElement(IbanElement);
      console.log("[iban]", iban);
      if (name == "") {
        setPaymentLoading(false);
        return Toast.fire({
          type: "error",
          title: "Please enter a vaild name.",
        });
      } else if (name != sepaValue.company_name && name != sepaValue.name) {
        setPaymentLoading(false);
        return Toast.fire({
          type: "error",
          title: "Please enter a vaild name which match your profile.",
        });
      } else if (email == "") {
        setPaymentLoading(false);
        return Toast.fire({
          type: "error",
          title: "Please enter a vaild email.",
        });
      } else if (!iban) {
        setPaymentLoading(false);
        return Toast.fire({
          type: "error",
          title: "Please enter a vaild IBAN.",
        });
      }

      //const result = await stripe.createSource(iban, sourceData);

      const PaymentMethod = await stripe.createPaymentMethod({
        type: "sepa_debit",
        sepa_debit: iban,
        billing_details: {
          name: name,
          email: email,
        },
      });
      if (PaymentMethod.error) {
        setPaymentLoading(false);
        return Toast.fire({
          type: "error",
          title: PaymentMethod.error.message,
        });
      } else {
        console.log("paymentMethod", PaymentMethod.paymentMethod);
      }

      var trustap_params = {
        user_id: JwtDecode(isAuthenticated()).id,
        amount: deposit_amount,
        mode: process.env.REACT_APP_PAYMENT_MODE,
        payment_method: "sepa",
      };

      console.log("+++++++++++++++++TrustapDeposit_params", trustap_params);

      const res = await request(
        "post",
        "/TrustapDeposit",
        trustap_params,
        false,
        true
      );
      console.log("+++++++++++++++++TrustapDeposit res", res.data);
      if (res.data.success) {
        if (res.data.results.transaction_id && res.data.results.client_secret) {
          const { paymentIntent, error } = await stripe.confirmSepaDebitPayment(
            res.data.results.client_secret,
            {
              payment_method: PaymentMethod.paymentMethod.id,
            }
          );
          if (error || !paymentIntent) {
            console.log("confirmSepaDebitPayment+++++++++++++++++e", error);
            setPaymentLoading(false);
            return Toast.fire({ type: "error", title: error });
          } else {
            console.log(
              "confirmSepaDebitPayment+++++++++++++++++s",
              paymentIntent
            );
            //trustap_params['transid'] = paymentIntent.id
            trustap_params["transaction_id"] = res.data.results.transaction_id;
          }
        } else {
          setPaymentLoading(false);
          return Toast.fire({
            type: "error",
            title: "Trustap transanction creation error",
          });
        }

        console.log("updateUserDeposit======placeorder_params", trustap_params);

        const res2 = await request(
          "post",
          "/updateUserDeposit",
          trustap_params,
          false,
          true
        );
        console.log("updateUserDeposit====== res", res2.data);
        if (res2.data.success) {
          setPaymentLoading(false);
          toggleMod1();
          onsubmitbid(null, "");
          //return Toast.fire({ title: 'Deposit Paid Successfully!', type: 'success' })
        } else {
          setPaymentLoading(false);
          return Toast.fire({ type: "error", title: "Deposit update error" });
        }
      } else {
        setPaymentLoading(false);
        return Toast.fire({ type: "error", title: res.data.message });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sepaValueChange = (evt) => {
    var { name, value } = evt.target;
    setSepaValue({
      ...sepaValue,
      [name]: value,
    });
  };

  useEffect(() => {
    // const getDet = async () => {
    //   const res = await request(
    //     "post",
    //     "/viewAllCard",
    //     { mode: "sandbox" },
    //     false,
    //     true
    //   );

    //   if (res.data.message === "No data") {
    //     setCards(true);
    //   }
    // };
    // getDet();
    getAllCards();
  }, []);

  const confirmbidplace = async (e, type) => {
    console.log("confirmbidplace===========");

    if (cards) {
      Toast.fire({ type: "warning", title: "Please add your card" });
      return history.push({
        pathname: "/saved-details",
        state: { id: features.proddetails.id },
      });
    }

    setModopen1(false);
    setModopen2(false);
    // setValues({...values,bidplaced:true})
    setBidplac(false);
    console.log("Current User Role_______", localStorage.getItem("userRole"));
    if (
      localStorage.getItem("userRole") == "tier2" ||
      localStorage.getItem("userRole") == "tier3"
    ) {
      // console.log("e.target", e.target);
      var bidsenddata = {
        wsprice: values.bid_amount
          ? values.bid_amount.toString().split(",").join("")
          : e.target.value.toString().split(",").join(""),
        first_name: state.user.first_name,
        last_name: state.user.last_name,
        id: features.proddetails.id,
        email: state.user.email,
        bid_increment: state.increment_amount,
      };
      if (type == "max" || bidMax) {
        bidsenddata.bid_max = true;
      } else {
        bidsenddata.hard_bid = 1;
      }

      const res = await request(
        "post",
        "/mobileconfirmForward",
        bidsenddata,
        false,
        true
      );
    } else {
      setModelopen1(!modalopen1);
    }
  };

  const auctionedProduct = async (product_id) => {
    var amount = "";
    try {
      const res = await request(
        "post",
        "/getprodetails",
        { product_id },
        false,
        true
      );

      if (res.data.success === "yes") {
        rprice = parseInt(res.data.results.rprice);
        // var send = {
        //   user_id: res.data.results.user_id,
        //   protitle: res.data.results.title,
        //   project_id: res.data.results.id,
        //   bprice: res.data.results.bprice,
        // };
        // const r = request("post", "/createlisting", send, false, true);
        res.data.attachments.map((imageValue) =>
          images.push({
            original: IMAGE_URL + imageValue.src,
            thumbnail: IMAGE_URL + imageValue.src,
          })
        );
        b = parseInt(res.data.results.cbidnot);
        //var change=[];
        //change.push(res.data.biddetails.bidtopstatus.toString().split("$"))
        //res.data.biddetails.bidtopstatus=change[0][0].toString()+"$ "+change[0][1].toString()
        // // console.log("==========",change)
        // res.data.biddetails.bidtopstatus.split("$").join("$")
        setValues({ ...values, next_bid: res.data.biddetails.next_bid });
        // setBidplac(res.data.biddetails.user_bidded_status);
        // console.log("bbbbbb",)

        if (
          parseInt(res.data.results.latestbid) <= 500 ||
          res.data.results.latestbid === null
        ) {
          amount = 5;
        } else {
          amount = 10;
        }

        if (
          res.data.biddetails.bidtopwinner &&
          res.data.results.market_status == "open"
        ) {
          let reserveCheck = res.data.biddetails.bidtopstatus.split(".");
          if (reserveCheck.length > 1) {
            console.log("++++++innnnnnn");
            var autobid = res.data.biddetails.bidtopstatus
              .split(". ")[0]
              .replace(/[^\d.]/g, "");
            var autobid1 =
              parseInt(res.data.biddetails.next_bid) - parseInt(amount);
            if (parseInt(autobid) < parseInt(res.data.results.rprice)) {
              if (parseInt(autobid) === parseInt(autobid1)) {
                res.data.biddetails.bidtopstatus = `Your bid of $ ${parseInt(
                  autobid1
                ).toLocaleString()} on this item placed, but reserve price not yet met.`;
                console.log(
                  "innnnnnnnnnn++++",
                  res.data.biddetails.bidtopstatus
                );
              } else {
                res.data.biddetails.bidtopstatus = `Your bid of $ ${parseInt(
                  autobid
                ).toLocaleString()} and Max-Bid of $ ${parseInt(
                  autobid1
                ).toLocaleString()} on this item placed, but reserve price not yet met.`;
              }
            } else {
              if (parseInt(autobid) === parseInt(autobid1)) {
                res.data.biddetails.bidtopstatus = `You are winning this item at $ ${parseInt(
                  autobid1
                ).toLocaleString()} and reserve price met.`;
              } else {
                res.data.biddetails.bidtopstatus = `You are winning this item at $ ${parseInt(
                  autobid
                ).toLocaleString()} and reserve price met. Your Max-Bid of $ ${parseInt(
                  autobid1
                ).toLocaleString()} has been placed successfully.`;
              }
            }
          }
        }

        if (res.data.biddetails.bidtopstatus.includes("losing")) {
          if (res.data.biddetails.bidtopstatus.includes("item")) {
            res.data.biddetails.bidtopstatus.replace("item", "batch");
          }

          if (
            res.data.biddetails.bidtopstatus.includes("and reserve price met")
          ) {
            res.data.biddetails.bidtopstatus.replace(
              " and reserve price met",
              ". Bid again to win"
            );
          } else {
            res.data.biddetails.bidtopstatus =
              res.data.biddetails.bidtopstatus + ". Bid again to win.";
          }
        }

        // } else if (
        //   parseInt(res.data.results.latestbid) < 1000 &&
        //   parseInt(res.data.results.latestbid) > 500
        // ) {
        //   amount = 10;
        // } else {
        //   amount = 50;
        // }
        //console.log("+++++", amount);

        const ffVideos = {
          embedUrl: `${process.env.REACT_APP_S3_BASE_URL}/farmfayre/${res.data.results.videos}`,
          thumbnail: "/images/playBg.png",
          renderItem: renderVideo,
        };

        images.unshift(ffVideos);

        setFeatures({
          ...features,
          prodImages: images,
          proddetails: res.data.results,
          addamount: amount,
          itemid: res.data.results.auctionid,
          proid: res.data.results.id,
        });
        //UserProfile(res.data.results);
        // if(res.data.results.length===1){
        getprofData(res.data.results.user_id);
        getSellerFeedback(res.data.results.user_id);
        // }

        console.log("+++++++++++features.proddetails", res.data.biddetails);
        setDateClosed(res.data.results.date_closed);
        setState({ ...state, bid_details: res.data.biddetails });
        setVideoModalOpen(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    if (multiitem) {
      getCalculation(product_id);
    }
    getHaulierPrice(product_id);
  };
  const getCalculation = async (id) => {
    //console.log("+++++++++++");
    var user_id = !isAuthenticated() ? 0 : JwtDecode(isAuthenticated()).id;
    const res = await request(
      "post",
      "/getprodetails_multiple",
      { product_id: id, userid: user_id },
      false,
      true
    );
    if (res.data && res.data.success == "yes") {
      var weight = 0,
        agemax = TimetoDate(res.data.results[1].content_head2),
        tdate = moment(res.data.results[1].inspectiontime).format("MM/DD/YYYY"),
        agemin = TimetoDate(res.data.results[1].content_head2),
        m = 0,
        owner = 0,
        day = TimetoDay(res.data.results[1].content_head4),
        sex1 = res.data.results[1].content_head5,
        breed1 = res.data.results[1].content_head1,
        qa1 = res.data.results[1].width,
        ex1 = res.data.results[1].finish,
        cre = res.data.results[1].manufacturer;

      // let age=0;
      res.data.results.map((value, key) => {
        weight = weight + value.weight;
        //  m=parseInt(TimetoDate(value.content_head2))
        //  console.log("++++++++++",parseInt(age))
        {
          res.data.attachments["product_image" + `${key + 2}`] &&
            res.data.attachments["product_image" + `${key + 2}`].map((data) => {
              images.push({
                original: AvatarURL + data,
                thumbnail: AvatarURL + data,
              });
            });
        }

        if (agemax < parseInt(TimetoDate(value.content_head2)) && key != "0") {
          agemax = parseInt(TimetoDate(value.content_head2));
          console.log("=======", agemax, key);
        }
        if (agemin > parseInt(TimetoDate(value.content_head2)) && key != "0") {
          agemin = parseInt(TimetoDate(value.content_head2));
        }

        if (owner < parseInt(value.height) && key != "0") {
          owner = parseInt(value.height);
        }
        if (sex1 !== value.content_head5 && key != "0") {
          sex1 = "0";
        }
        if (breed1 !== value.content_head1 && key != "0") {
          breed1 = "0";
        }

        if (day > TimetoDay(value.content_head4) && key != "0") {
          day = TimetoDay(value.content_head4);
        }
        if (
          tdate > moment(value.inspectiontime).format("MM/DD/YYYY") &&
          key != "0"
        ) {
          tdate = moment(value.inspectiontime).format("MM/DD/YYYY");
        }
        if (qa1 !== value.width && key != "0") {
          qa1 = "m";
        }
        if (ex1 !== value.finish && key != "0") {
          ex1 = "m";
        }
        if (cre !== value.manufacturer && key != "0") {
          cre = "m";
        }
      });
      console.log("width - ", res.data.results[0].width);
      const width = res.data.results[0].width.toString().split("");
      const filteredNames = width
        .map((digit) => {
          const matchedItem = Quality_asurance.find(
            (val) => parseInt(val.value) === parseInt(digit)
          );
          return matchedItem ? matchedItem.name : null;
        })
        .filter((name) => name !== null)
        .join(", ");

      const contentHead = res.data.results[1].content_head1;
      const breeds = contentHead.split(",");

      const shortNames = breeds.map((breed) => {
        const foundBreed = BreedshortNames.find(
          (b) => b.fname.trim() === breed.trim()
        );
        const foundBreedICBF = BreedshortNamesICBF.find(
          (b) => b.fname.trim() === breed.trim()
        );
        return foundBreed
          ? foundBreed.sname
          : foundBreedICBF
          ? foundBreedICBF.sname
          : breed.trim();
      });

      const sname = shortNames.join(",");
      setItem({
        ...mulitem,
        wei: Math.round(
          parseInt(weight) / parseInt(res.data.results.length - 1)
        ),
        agemi: agemin,
        agema: agemax,
        own: owner,
        sex: sex1 === "0" ? "Mixed" : res.data.results[1].content_head5,
        breed: breed1 === "0" ? "Mixed" : sname,
        len: res.data.results.length,
        days: isNaN(day) ? res.data.results[1].herd_days : day,
        testdate: tdate,
        cr: cre === "m" ? "Mixed" : res.data.results[1].manufacturer,
        ex: ex1 === "m" ? "Mixed" : res.data.results[1].finish,
        qa: filteredNames ? filteredNames : "No",
        wm: res.data.results[1].content_head3,
      });

      const strArr = filteredNames.split(", ");

      const group = new Map([
        ["FeedLot Status", "/images/FEEDLOTSTATUSONLY.png"],
        ["ABP Advantage", "/images/ABPAdvantageClub.png"],
        ["Organic", "/images/Organic.png"],
        ["Twenty20 Club", "/images/Twenty20Club.png"],
      ]);

      const result = strArr.map((str) => group.get(str)).filter((url) => url);

      setWatermarkImage(result);

      // if (filteredNames == "FeedLot Status") {
      //   setWatermarkImage("/images/FEEDLOTSTATUSONLY.png");
      // } else if (filteredNames == "ABP Advantage") {
      //   setWatermarkImage("/images/ABPAdvantageClub.png");
      // } else if (filteredNames == "Organic") {
      //   setWatermarkImage("/images/Organic.png");
      // } else if (filteredNames == "Twenty20 Club") {
      //   setWatermarkImage("/images/Twenty20Club.png");
      // }
    }
  };

  const getHaulierPrice = async (id) => {
    //console.log("+++++++++++");

    if (!isAuthenticated()) {
      setLoading(false);
      setValues2({
        ...values2,
        haulier: "We don't have enough details to estimate haulage cost",
        haulier_amount: 0,
      });
    } else {
      const res = await request(
        "post",
        "/getHaulierPrice",
        { product_id: id, userid: JwtDecode(isAuthenticated()).id },
        false,
        true
      );
      setLoading(false);
      if (res.data && res.data.success == "yes") {
        setValues2({
          ...values2,
          haulier: res.data.haulier,
          haulier_amount: res.data.haulier_amount,
        });
      }
    }
  };
  const getBidDetails = async (product_id) => {
    try {
      const res = await request(
        "post",
        "/getbiddetails",
        { product_id },
        false,
        true
      );

      if (res.data.success === "yes") {
        setBidApi((prev) => ({
          ...prev,
          data: res.data.results,
          bid_count: res.data.results.length,
        }));
        const isUserBided =
          res.data.results.filter((item) => item.user_id == userRef.current)
            .length > 0;
        setQATerm(isUserBided);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const UserProfile = async () => {
    const res = await request(
      "post",
      "/getUserDetails",
      {
        user_id: JwtDecode(isAuthenticated()).id,
      },
      false,
      true
    );
    //  userData = ;
    res.data && setData(JwtDecode(res.data.data));
  };

  useEffect(() => {
    productRef.current = features.proddetails;
    userRef.current = localStorage.userId;
  });

  useEffect(() => {
    socket.on("realclosedupdates", (data) => {
      if (parseInt(productRef.current.id) == parseInt(data.pid)) {
        if (parseInt(userRef.current) != parseInt(data.usr)) {
          setBuynowdisabled(true);
        }
      }
    });
    return () => {
      socket.off("realclosedupdates", (data) => {
        if (parseInt(productRef.current.id) == parseInt(data.pid)) {
          if (parseInt(userRef.current) != parseInt(data.usr)) {
            setBuynowdisabled(true);
          }
        }
      });
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated()) {
      UserProfile();
    }

    auctionedProduct(props.match.params.id);
    getBidDetails(props.match.params.id);
    //UserProfile();
  }, []);

  useEffect(() => {
    realClosedUpdates((data) => {
      const project_id = props.match.params.id;
      console.log("socket.data", data.usr, parseInt(state.user.id));
      if (data.pid === parseInt(project_id)) {
        if (data.usr !== "") {
          console.log(
            "innnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn",
            data.usr,
            parseInt(state.user.id)
          );
          if (parseInt(state.user.id) === data.usr) {
            setState((prevState) => ({
              ...prevState,
              bid_details: {
                ...prevState.bid_details,
                bidtopstatus: `Congratulations! You won this item.`,
              },
            }));
          } else if (parseInt(bid_or_not) > 0) {
            setState((prevState) => ({
              ...prevState,
              bid_details: {
                ...prevState.bid_details,
                bidtopstatus: `Unfortunately, you lost this item.`,
              },
            }));
          }
        }
      }
    });

    bidAddtime((data) => {
      console.log("Socket data", data);
      if (parseInt(data.id) === parseInt(props.match.params.id)) {
        if (parseInt(data.bpop_belowFive) === 1) {
          console.log("new prodcut close time", data.bpop_belowFiveIncrement);
          setDateClosed(data.bpop_belowFiveIncrement);
        }
        if (
          data.status === "failed" &&
          parseInt(state.user.id) === parseInt(data.bpop_cbidder)
        ) {
          return Toast.fire({ title: data.error, type: "error" });
        }

        if (data.status !== "failed") {
          var addamount = "";
          if (parseInt(data.bpop_wprice) <= 500) {
            addamount = 5;
          } else {
            addamount = 10;
          }
          if (parseInt(state.user.id) === parseInt(data.bpop_cbidder)) {
            Toast.fire({ title: data.bpop_error, type: "success" });
            setValues({ ...values, next_bid: data.bpop_cuser_nextbid });
            var message = [];
            message = data.bpop_bidstatus.split(".");
            console.log("+++++++++++++++++++++", message);
            var msg = "";
            if (data.bpop_wprice === data.bpop_wprice_morehigh) {
              msg =
                message[0].toString().split("$").join("$ ") +
                " and reserve price met";
            } else {
              msg =
                message[0].toString().split("$").join("$ ") +
                " and reserve price met.";
              if (message.length > 1) {
                msg = msg + message[1].toString().split("$").join("$ ") + ".";
              }
            }
            if (parseInt(state.user.id) === parseInt(data.bpop_higher)) {
              if (parseInt(rprice) > parseInt(data.bpop_wprice)) {
                if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                  msg = `Your bid of $ ${parseFloat(
                    data.bpop_wprice
                  ).toLocaleString()} on this item placed, but reserve price not yet met.`;
                } else {
                  msg = `Your bid of $ ${parseFloat(
                    data.bpop_wprice
                  ).toLocaleString()} and Max-Bid of $ ${parseFloat(
                    data.bpop_wprice_morehigh
                  ).toLocaleString()} on this item placed, but reserve price not yet met.`;
                }
              }
            }

            setState((prevState) => ({
              ...prevState,
              bid_details: {
                ...prevState.bid_details,
                bidtopstatus: msg,
              },
            }));
            setFeatures((prevState) => ({
              ...prevState,
              proddetails: {
                ...prevState.proddetails,
                latestbid: data.bpop_wprice,
              },
              addamount: addamount,
            }));
            setbid_or_not(1);
            b = 1;
            setBidApi((prev) => ({
              ...prev,
              bid_count: data.bpop_bidcount,
            }));
          } else {
            if (parseInt(bid_or_not) > 0 || parseInt(b) > 0) {
              console.log(bid_or_not, "Bid or Not if", "b = ", b);
              if (parseInt(state.user.id) === parseInt(data.bpop_higher)) {
                console.log("Higher Bidder if");
                if (parseInt(values.next_bid) < parseInt(data.bpop_nextbid)) {
                  console.log("Inside Higher Bidder if");
                  setValues({ ...values, next_bid: data.bpop_nextbid_org });
                  var msg = "";
                  if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                    msg = `You are winning this item at $ ${callNumber(
                      parseInt(data.bpop_wprice)
                    )} and reserve price met.`;
                  } else {
                    msg = `You are winning this item at $ ${callNumber(
                      parseInt(data.bpop_wprice)
                    )} and reserve price met.Your autoBid of $ ${callNumber(
                      parseInt(data.bpop_wprice_morehigh)
                    )} has been placed successfully.`;
                  }

                  if (parseInt(rprice) > parseInt(data.bpop_wprice)) {
                    if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                      msg = `Your bid of $ ${parseFloat(
                        data.bpop_wprice
                      ).toLocaleString()} on this item placed, but reserve price not yet met.`;
                    } else {
                      msg = `Your bid of $ ${parseFloat(
                        data.bpop_wprice
                      ).toLocaleString()} and Max-Bid of $ ${parseFloat(
                        data.bpop_wprice_morehigh
                      ).toLocaleString()} on this item placed, but reserve price not yet met.`;
                    }
                  }

                  setState((prevState) => ({
                    ...prevState,
                    bid_details: {
                      ...prevState.bid_details,
                      bidtopstatus: msg,
                    },
                  }));

                  setBidApi((prev) => ({
                    ...prev,
                    bid_count: data.bpop_bidcount,
                  }));
                  setFeatures((prevState) => ({
                    ...prevState,
                    proddetails: {
                      ...prevState.proddetails,
                      latestbid: data.bpop_wprice,
                    },
                  }));
                  setbid_or_not(1);
                } else {
                  console.log("Inside Higher Bidder else");
                  var msg = "";
                  if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                    msg = `You are winning this item at $ ${callNumber(
                      parseInt(data.bpop_wprice)
                    )} and reserve price met.`;
                  } else {
                    msg = `You are winning this item at $ ${callNumber(
                      parseInt(data.bpop_wprice)
                    )} and reserve price met.Your autoBid of $ ${callNumber(
                      parseInt(data.bpop_wprice_morehigh)
                    )} has been placed successfully.`;
                  }

                  if (parseInt(rprice) > parseInt(data.bpop_wprice)) {
                    if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                      msg = `Your bid of $ ${parseFloat(
                        data.bpop_wprice
                      ).toLocaleString()} on this item placed, but reserve price not yet met.`;
                    } else {
                      msg = `Your bid of $ ${parseFloat(
                        data.bpop_wprice
                      ).toLocaleString()} and Max-Bid of $ ${parseFloat(
                        data.bpop_wprice_morehigh
                      ).toLocaleString()} on this item placed, but reserve price not yet met.`;
                    }
                  }
                  setState((prevState) => ({
                    ...prevState,
                    bid_details: {
                      ...prevState.bid_details,
                      bidtopstatus: msg,
                    },
                  }));
                  setBidApi((prev) => ({
                    ...prev,
                    bid_count: data.bpop_bidcount,
                  }));
                  setFeatures((prevState) => ({
                    ...prevState,
                    proddetails: {
                      ...prevState.proddetails,
                      latestbid: data.bpop_wprice,
                      addamount: addamount,
                    },
                  }));
                }
              } else {
                console.log("Higher Bidder else");
                setValues({ ...values, next_bid: data.bpop_nextbid_org });
                setState((prevState) => ({
                  ...prevState,
                  bid_details: {
                    ...prevState.bid_details,
                    bidtopstatus: `You're losing this batch, user ${data.bpop_biddersname.charAt(
                      0
                    )}****${data.bpop_biddersname.charAt(
                      data.bpop_biddersname.length - 1
                    )} has placed a higher autoBid.Bid again to win.`,
                  },
                }));

                setBidApi((prev) => ({
                  ...prev,
                  bid_count: data.bpop_bidcount,
                }));
                setFeatures((prevState) => ({
                  ...prevState,
                  proddetails: {
                    ...prevState.proddetails,
                    latestbid: data.bpop_wprice,
                  },
                }));
                setbid_or_not(1);
              }
            } else {
              console.log(bid_or_not, "Bid or Not else");
              setValues({ ...values, next_bid: data.bpop_nextbid_org });

              setBidApi((prev) => ({ ...prev, bid_count: data.bpop_bidcount }));
              setFeatures((prevState) => ({
                ...prevState,
                proddetails: {
                  ...prevState.proddetails,
                  latestbid: data.bpop_wprice,
                },
              }));
            }
          }
          getBidDetails(props.match.params.id);
        }
      }
    });
  }, []);
  // console.log(features)
  // const [message, setMessage] = useState({
  //   from_id: state.user.id,
  //   to_id: "",
  //   seller_id:features.proddetails.user_id,
  //   project_id: props.match.params.id,
  //   current_id: state.user.id,
  // });
  // const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [val, setVal] = useState(0);
  const [reviewModalOpen, setReviewModalOpen] = React.useState(false);
  const [modalopen1, setModelopen1] = React.useState(false);
  const [modalopen2, setModelopen2] = React.useState(false);
  const [modalopen3, setModelopen3] = React.useState(false);
  const [modalopen4, setModelopen4] = React.useState(false);
  const [modalopen5, setModelopen5] = React.useState(false);
  const [modalopen6, setModelopen6] = React.useState(false);
  const [modalopen7, setModelopen7] = React.useState(false);
  const [modalopen8, setModalopen8] = React.useState(false);
  const [modopen1, setModopen1] = React.useState(false);
  const [modopen2, setModopen2] = React.useState(false);
  const [profData, setProfdata] = useState([]);
  const [sellerFeedback, setSellerFeedback] = useState([]);
  const [display, seDis] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const multiitem =
    history.location.pathname === `/multi-items-view/${props.match.params.id}`;
  const buyNow = async () => {
    toggleModal2();
    if (!isAuthenticated()) {
      Toast.fire({ title: "Please login to continue!", icon: "info" });
      return props.history.push({
        pathname: "/login",

        state: { path: `/checkout/${features.proddetails.id}` },
      });
    }
    if (String(props.timer) === "true") {
      toggleModal2();
      return Toast.fire({
        title: "Buy this product once auction started!",
        icon: "warning",
      });
    }
    if (
      localStorage.getItem("userRole") == "tier2" ||
      localStorage.getItem("userRole") == "tier3"
    ) {
      const res = await request(
        "post",
        "/watchlist",
        {
          id: features.proddetails.id,
          userid: JwtDecode(isAuthenticated()).id,
        },
        false,
        true
      );
      //  if(res.data.success="yes"){
      //   Toast.fire({type:"success",title:"Lot added to Favourite"})
      // }
      // else
      // {
      //  Toast.fire({type:"error",title:"Something went wrong"})
      // }

      if (localStorage.getItem("trustapGuestID") == "") {
        localStorage.clear();
        localStorage.setItem("newspopup", 1);
        window.location.href = "/";
        return false;
      } else {
        props.history.push({
          pathname: `/checkout/buynow/${features.proddetails.id}`,
        });
      }
    } else {
      setModelopen1(!modalopen1);
    }
  };
  const handleClose1 = () => {
    setVideoModalOpen(false);
  };
  const getprofData = async (id) => {
    try {
      const res = await request(
        "post",
        "/getUserProfileDetails",
        {
          user_id: id,
        },
        false,
        true
      );
      //console.log("kkkkkkk", res);
      if (res.data.success === "yes") {
        setProfdata(res.data.data);
        //console.log("UserProfileDetails", res.data.data);
        seDis(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSellerFeedback = async (sellerId) => {
    const res = await request("post", "/getSellerFeedbacks", {
      seller_id: sellerId,
    });
    if (res.data && res.data.status === "yes") {
      var feedback = res.data.data.filter((item) => {
        return (
          item.subject != "review_1" &&
          item.subject != "review_2" &&
          item.subject != "review_3" &&
          item.subject != "review_4" &&
          item.subject != "review_5"
        );
      });
      setSellerFeedback(feedback);
    }
  };

  const getSellerRatings = () => {
    var ratingSum = 0;
    sellerFeedback.forEach((feedback) => {
      ratingSum += feedback.rating;
    });

    return ratingSum / sellerFeedback.length;
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const toggleModal = () => {
    setModopen1(!modopen1);
  };
  const toggleMod1 = () => {
    setModopen1(!modopen1);
    console.log(paymentModalOpen, "paymentModalOpen");
    if (paymentModalOpen == true) {
      setPaymentModalOpen(!modopen1);
    }
  };
  const toggleMod2 = () => {
    setModopen2(!modopen2);
  };
  const toggleModal2 = () => {
    setModelopen2(false);
    setModelopen3(false);
    setModelopen4(false);
    setModelopen5(false);
    setModelopen6(false);
    setModelopen7(false);
    setModalopen8(false);
  };
  const toggleModal1 = () => {
    setModelopen1(!modalopen1);
  };
  const viewReviewsClickHanlder = () => {
    setReviewModalOpen(true);
  };
  const closeReviewsModal = () => {
    setReviewModalOpen(false);
  };
  const togglePaymentModal = () => {
    setPaymentModalOpen(!paymentModalOpen);
  };
  const moveone = (name) => (e) => {
    if (name == "log") {
      window.location.href = "/login";
    } else {
      if (localStorage.getItem("userRole") === "tier1") {
        window.location.href = "/Reg_tier_2";
      } else {
        window.location.href = "/Reg_tier_1";
      }
    }
  };
  const moveon = (e) => {
    return props.history.push({
      pathname: "/Reg_tier_2",
      state: {
        path: props.history.location.pathname,
      },
    });
  };

  const sellerrating = async (event, newValue) => {
    setVal(newValue);
    var data = {
      use_id: JwtDecode(isAuthenticated()).id,
      product_id: features.proddetails.id,
      seller_id: features.proddetails.user_id,
      ratings: newValue,
    };
    const res = await request("post", "/give_ratings", data, false, true);
  };

  //console.log("+++++++++++message.proddetails", props.history.location.state);

  let currentDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

  let windowWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  // const loc=features.proddetails.location? features.proddetails.location:"";

  useEffect(() => {
    window.scrollTo({ top: 130, left: 0, behavior: "smooth" });
  }, []);

  const vistorModalClosehandler = () => {
    setVisitorModalOpen(false);
  };

  const renderVideo = (items) => {
    return (
      <div className="image-gallery-image">
        <video
          controls
          width="100%"
          height="100%"
          className="video"
          hidden={!s3_video}
          autoPlay
          muted
        >
          <source
            src={items.embedUrl}
            type="video/mp4"
            onError={(e) => setS3video(false)}
          ></source>
        </video>
      </div>
    );
  };

  return (
    <Layout>
      {/* <div className="">Auction ends in <span className="material-icons">timer</span> 02 Hours  32 Mins 42 Sec</div> */}
      <div className="timerHead">
        <span className="material-icons">timer</span>
        {features.proddetails.date_added === null ||
        features.proddetails.date_closed === null ? (
          <span>Pending Approval</span>
        ) : features.proddetails.market_status == "sold" ? (
          <span>Sale Closed</span>
        ) : (
          <Timer
            date_closed={features.proddetails.date_closed}
            date_added={features.proddetails.date_added}
            withText={1}
          />
        )}
      </div>
      <div className="product-wrapper">
        <div className="container">
          {isAuthenticated ? (
            <div
              className={`biddingMessages ${
                state.bid_details.bidtopstatus
                  ? state.bid_details.bidtopstatus.includes("lost") ||
                    state.bid_details.bidtopstatus.includes("losing")
                    ? "lost"
                    : state.bid_details.bidtopstatus.includes("winning")
                    ? "winning"
                    : "won"
                  : null
              } d-flex justify-content-center align-items-center`}
            >
              <h3>
                {state.bid_details.bidtopstatus
                  ? state.bid_details.bidtopstatus.includes("lost") ||
                    state.bid_details.bidtopstatus.includes("losing") ||
                    state.bid_details.bidtopstatus.includes("reserve price")
                    ? Sentencecase(
                        state.bid_details.bidtopstatus
                          .split("autoBid")
                          .join("Max-Bid")
                          .split("$")
                          .join("€ ")
                      )
                    : state.bid_details.bidtopstatus.includes("winning") ||
                      state.bid_details.bidtopstatus.includes("won")
                    ? Sentencecase(
                        state.bid_details.bidtopstatus
                          .toString()
                          .split("$")
                          .join("€ ")
                      )
                    : ""
                  : ""}
              </h3>
            </div>
          ) : (
            ""
          )}

          <div className="auction-num1">
            <div>
              {features.proddetails.wlistpop !== undefined && (
                <FavoriteCheckbox
                  favIdChild={features.proddetails.id}
                  onCall={addchange}
                  watchlisted={features.proddetails.wlistpop}
                />
              )}
            </div>
            Sale No:
            {features.proddetails.location
              ? " " +
                SaleNumber(
                  features.proddetails.location,
                  features.proddetails.date_added,
                  features.proddetails.date_closed
                ) +
                features.itemid
                  .toString()
                  .slice(features.itemid.toString().length - 4)
              : ""}
          </div>

          <div className="item-title">{features.proddetails.title}</div>
          <div className="row">
            <div className="col-lg-7 mb-3">
              {console.log(features.prodImages, features, "prodImages")}
              <div className="position-relative">
                <div className="watermark">
                  {watermarkImage.map((imageURL) => (
                    <img src={imageURL} className="mb-3" />
                  ))}
                </div>

                <ImageGallery
                  items={features.prodImages}
                  thumbnailPosition={`${
                    windowWidth <= 500 ? "bottom" : "left"
                  }`}
                  showNav={true}
                  showBullets={false}
                  showFullscreenButton={true}
                  showPlayButton={false}
                />
              </div>
            </div>

            {/* Bidding has not be configured yes */}

            {/* console.log('features', features.proddetails.date_added, features.proddetails.market_status) */}

            <div className="col-lg-5" hidden={loading}>
              {matches ? (
                <Button
                  className="pl-2 ffWatchVideo asdasd"
                  onClick={() => setVideoModalOpen(true)}
                >
                  <span className="material-icons">play_circle_filled</span>
                  Watch video
                </Button>
              ) : null}
              {features.proddetails.auction === 1 &&
              state.user.role !== "haulier" ? (
                <div
                  className={`current-bid ${
                    features.proddetails.latestbid >=
                      features.proddetails.rprice && "resPriceMet"
                  }`}
                >
                  {features.proddetails.latestbid ? (
                    features.proddetails.latestbid <
                    features.proddetails.rprice ? (
                      <>
                        <span>
                          Bid: € <wbr />
                          {`${callNumber(
                            features.proddetails.latestbid
                          )} (€ ${perkgamount(
                            features.proddetails.latestbid,
                            parseFloat(mulitem.wei) > 0
                              ? mulitem.wei
                              : features.proddetails.weight
                          )}/Kg)`}
                        </span>
                        <span>
                          {features.proddetails.modelnumber === "1"
                            ? "(Reserve not met)"
                            : "(Reserve: € " +
                              callNumber(features.proddetails.rprice) +
                              ")"}
                        </span>
                        {/* {props.history.location.state === "0" ? (
                          <div className="resPrice">
                            Reserve price: € {features.proddetails.rprice}
                          </div>
                        ) : (
                          ""
                        )} */}
                      </>
                    ) : (
                      <>
                        Bid: €{" "}
                        {`${callNumber(
                          features.proddetails.latestbid
                        )} (€ ${perkgamount(
                          features.proddetails.latestbid,
                          parseFloat(mulitem.wei) > 0
                            ? mulitem.wei
                            : features.proddetails.weight
                        )}/Kg)`}
                        {features.proddetails.modelnumber === "1"
                          ? "(Reserve met)"
                          : "(Reserve: € " +
                            callNumber(features.proddetails.rprice) +
                            ")"}
                        {/* {props.history.location.state === "0" ? (
                          <div className="resPrice">
                            Reserve price: € {features.proddetails.rprice}
                          </div>
                        ) : (
                          ""
                        )} */}
                      </>
                    )
                  ) : (
                    <>
                      {features.proddetails.market_status == "sold"
                        ? "SOLD"
                        : "NO BIDS"}
                    </>
                  )}
                </div>
              ) : (
                ""
              )}
              {moment(features.proddetails.date_added).format(
                "YYYY-MM-DD HH:mm:ss"
              ) < currentDate &&
              features.proddetails.market_status == "open" &&
              state.user.role !== "haulier" ? (
                <>
                  {state.user.email != features.proddetails.seller_email &&
                  features.proddetails.auction == 1 ? (
                    <div className="row pbid-wrap">
                      <div className="col-sm-6">
                        <CustomInput
                          id="bid-amount"
                          name={"bid"}
                          label={"Enter bid"}
                          // con={true}
                          placeholder={callNumber(values.next_bid)}
                          value={
                            callNumber(
                              values.bid_amount.toString().split(",").join("")
                            ) == 0
                              ? ""
                              : callNumber(
                                  values.bid_amount
                                    .toString()
                                    .split(",")
                                    .join("")
                                )
                          }
                          onchange={handlechange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <span className="material-icons">euro</span>
                              </InputAdornment>
                            ),
                            onKeyPress: NumberOnly,
                          }}
                          tooltiptitle="Whatever amount you place in here, that is your Bid."
                        />
                      </div>
                      <div className="col-sm-6 pmb-btn">
                        <PrimaryButton
                          label="Place my bid"
                          dis={error.bid || !values.bid_amount ? true : false}
                          onsubmit={(e) => {
                            if (
                              !isAuthenticated() ||
                              localStorage.getItem("userRole") === "tier1"
                            ) {
                              setVisitorModalOpen(true);
                            } else {
                              if (
                                mulitem.qa.includes("FeedLot Status") &&
                                QATerm == false
                              ) {
                                setModalopen8(true);
                              } else if (values2.haulier_amount == 0) {
                                setModelopen6(!modalopen6);
                                return false;
                              } else if (
                                userData.aboutme !== "1" ||
                                mulitem.qa.includes("FeedLot Status Only")
                              ) {
                                setModelopen3(true);
                              } else {
                                setBidMax(false);
                                onsubmitbid(e);
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {error.bid && <p className="text-danger">{error.bid}</p>}
                  {state.user.email != features.proddetails.seller_email &&
                  features.proddetails.auction == 1 ? (
                    <div className="em-bid">
                      <span onClick={popClick}>Enter max bid</span>
                      <Tooltip
                        title="Set your maximum bid and let the system bid for you, even when you're not online. You'll only pay one increment above the next best bid. We value your time!"
                        aria-label="Enter max bid"
                      >
                        <span className="material-icons">info</span>
                      </Tooltip>
                    </div>
                  ) : (
                    ""
                  )}
                  {state.user.email != features.proddetails.seller_email ? (
                    <Popover
                      id={maxBid}
                      open={openPop}
                      anchorEl={anchorEl}
                      onClose={popClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                    >
                      <div className="max-bid-container">
                        <div className="row pbid-wrap">
                          <div className="col-sm-6">
                            <CustomInput
                              id="bid-amount"
                              placeholder="Enter bid amount"
                              value={
                                callNumber(
                                  values.bid_amount
                                    .toString()
                                    .split(",")
                                    .join("")
                                ) == 0
                                  ? ""
                                  : callNumber(
                                      values.bid_amount
                                        .toString()
                                        .split(",")
                                        .join("")
                                    )
                              }
                              onchange={handlechange}
                              con={true}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <span className="material-icons">euro</span>
                                  </InputAdornment>
                                ),
                                onKeyPress: NumberOnly,
                              }}
                              tooltiptitle="Here you can set and forget. Place the maximum amount you are happy to Bid and our system will auto bid for you, even when your offline. Your highest bid will be the next best Bid, so don't worry if there is no second bidder you won't go to your “Max Bid”. Handy right?"
                            />
                          </div>
                          <div className="col-sm-6">
                            <PrimaryButton
                              onsubmit={(e) => {
                                if (values2.haulier_amount == 0) {
                                  setModelopen6(!modalopen6);
                                  return false;
                                }

                                if (userData.aboutme !== "1") {
                                  popClose();
                                  setModelopen5(!modalopen5);
                                } else {
                                  popClose();
                                  //setBidMax(true);
                                  onsubmitbid(e, "max");
                                }
                              }}
                              dis={
                                error.bid || !values.bid_amount ? true : false
                              }
                              value={"max"}
                              label="SET MAX BID"
                            />
                          </div>
                        </div>
                        {error.bid && (
                          <p className="text-danger">{error.bid}</p>
                        )}
                      </div>
                    </Popover>
                  ) : (
                    ""
                  )}
                  {/* {console.log("+++",state.user.email,features.proddetails.seller_email)} */}
                  {state.user.email != features.proddetails.seller_email &&
                  features.proddetails.auction == 1 ? (
                    <div className="or">
                      <span>OR</span>
                    </div>
                  ) : (
                    ""
                  )}
                  {state.user.email != features.proddetails.seller_email ? (
                    <div className="row">
                      <div className="col-sm-6 mb-2">
                        {console.log(
                          "ccc+++++++++++++++",
                          features.addamount,
                          features.proddetails.latestbid
                        )}
                        {features.proddetails.auction == 1 ? (
                          <button
                            onClick={(e) => {
                              if (
                                !isAuthenticated() ||
                                localStorage.getItem("userRole") === "tier1"
                              ) {
                                setVisitorModalOpen(true);
                              } else {
                                if (
                                  mulitem.qa.includes("FeedLot Status") &&
                                  QATerm == false
                                ) {
                                  setModalopen8(true);
                                } else if (values2.haulier_amount == 0) {
                                  setModelopen6(!modalopen6);
                                  return false;
                                } else if (
                                  NewCounties.includes(
                                    localStorage.getItem("userCounty")
                                  )
                                ) {
                                  handlechange(e);
                                } else {
                                  Toast.fire({
                                    type: "warning",
                                    title:
                                      "Initially, we are only launching in South East, so please hold off for a little while",
                                  });
                                }
                              }
                            }}
                            name="Quick"
                            value={
                              values.next_bid != null
                                ? parseInt(values.next_bid)
                                : parseInt(features.proddetails.sprice) +
                                  parseInt(features.addamount)
                            }
                            className="light-grn-btn"
                          >
                            Quick bid for €{" "}
                            {values.next_bid != null
                              ? callNumber(parseInt(values.next_bid))
                              : callNumber(
                                  parseInt(features.proddetails.sprice) +
                                    parseInt(5)
                                )}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-sm-6">
                        {features.proddetails.buynow == "1" ? (
                          <button
                            onClick={(e) => {
                              if (
                                !isAuthenticated() ||
                                localStorage.getItem("userRole") === "tier1"
                              ) {
                                setVisitorModalOpen(true);
                              } else {
                                if (values2.haulier_amount == 0) {
                                  setModelopen6(!modalopen6);
                                  return false;
                                }
                                if (
                                  NewCounties.toString().includes(
                                    localStorage.getItem("userCounty")
                                  )
                                ) {
                                  if (userData.aboutme !== "1") {
                                    setModelopen2(!modalopen2);
                                  } else {
                                    buyNow(e);
                                  }
                                } else {
                                  Toast.fire({
                                    type: "warning",
                                    title:
                                      "Initially, we are only launching in South East, so please hold off for a little while",
                                  });
                                }
                              }
                            }}
                            disabled={
                              features.proddetails.latestbid >=
                                features.proddetails.bprice ||
                              features.proddetails.market_status != "open" ||
                              buynowdisabled
                                ? true
                                : false
                            }
                            className={`light-grn-btn ${
                              features.proddetails.latestbid >
                                features.proddetails.bprice && "disabled"
                            }`}
                          >
                            BUY NOW AT €
                            {" " + callNumber(features.proddetails.bprice)}
                          </button>
                        ) : (
                          ""
                        )}
                        {isAuthenticated() && (
                          <Link to="/profile">
                            {" "}
                            <div className="one-click">
                              1-click bid{" "}
                              {userData.aboutme !== "1"
                                ? "disabled"
                                : "enabled"}
                              {/* <span className="material-icons" onClick={popClick1}>
                            help
                          </span> */}
                            </div>
                          </Link>
                        )}
                        {/* <Popover
                          id={oneClick}
                          open={openPop1}
                          anchorEl={anchorE2}
                          onClose={popClose1}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                        >
                          <p className="pover-wrap">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod{" "}
                            <Link to="/profile">Enable 1-click bid</Link>
                          </p>
                        </Popover> */}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <h3 className="auctionNotStarted">
                  {state.user.role != "haulier" &&
                  features.proddetails.market_status == "open"
                    ? "Sales Starting Shortly"
                    : ""}
                </h3>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <div className="row">
                <div className="col-md-7 d-flex justify-content-between align-items-center pvItemInfo">
                  <div className="item-id">
                    Item ID:
                    {" " +
                      ItemNum(
                        features.proddetails.location,
                        features.proddetails.date_added
                      ) +
                      features.proid
                        .toString()
                        .slice(features.proid.toString().length - 4)}
                  </div>
                  {matches ? null : (
                    <Button
                      className="pl-2 ffWatchVideo"
                      onClick={() => setVideoModalOpen(true)}
                    >
                      <span className="material-icons">play_circle_filled</span>
                      Watch video
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-7 allDetails">
              {multiitem && (
                <div className="total-items">
                  <div>
                    # Livestock:{" "}
                    {mulitem.len && mulitem.len != 0 ? mulitem.len - 1 : ""}
                  </div>
                  <div>
                    <a className="light-grn-btn" onClick={toggleDrawer}>
                      View all
                    </a>
                    <Drawer anchor="right" open={open} onClose={toggleDrawer}>
                      <span
                        onClick={toggleDrawer}
                        className="material-icons drawer-close"
                      >
                        close
                      </span>
                      <MultiItem
                        proddetails={features.proddetails}
                        qa={mulitem.qa ? mulitem.qa : ""}
                        id={features.proddetails.id}
                      />
                    </Drawer>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">
                    {multiitem ? "Avg. Weight (Kgs)" : "Weight(Kgs)"}
                  </div>
                  <div className="pcon-txt">
                    {multiitem
                      ? Math.round(mulitem.wei) == 0
                        ? "N/A"
                        : callNumber(Math.round(mulitem.wei)) + " kg"
                      : features.proddetails.weight + " kg"}
                  </div>
                </div>
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">Sex</div>
                  <div className="pcon-txt">
                    {multiitem
                      ? mulitem.sex
                      : features.proddetails.content_head5}
                  </div>
                </div>
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">
                    {multiitem ? "Age (Mths)" : "Age (M & D)"}
                  </div>
                  <div className="pcon-txt">
                    {multiitem
                      ? mulitem.agemi + "-" + mulitem.agema
                      : TimetoDate(features.proddetails.content_head2)}{" "}
                  </div>
                </div>
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">Breed</div>
                  <div className="pcon-txt">
                    {multiitem
                      ? mulitem.breed
                      : features.proddetails.content_head1}
                  </div>
                </div>
              </div>
              {/* {features.proddetails.category_id == "Beef" && (
                <div className="row">
                  <div className="col-6 col-md-3 form-group">
                    <div className="pcon-head">{multiitem ? "CBV" : "CBV"}</div>
                    <div className="pcon-txt">
                      {multiitem && mulitem.cbv ? mulitem.cbv : ""}
                    </div>
                  </div>
                  <div className="col-6 col-md-3 form-group">
                    <div className="pcon-head">Star Rating (In Breed</div>
                    <div className="pcon-txt">
                      {multiitem && mulitem.star_in_breed
                        ? mulitem.star_in_breed + "Star"
                        : "- Star"}
                    </div>
                  </div>
                  <div className="col-6 col-md-3 form-group">
                    <div className="pcon-head">Star Rating (Across Breeds</div>
                    <div className="pcon-txt">
                      {multiitem && mulitem.star_in_breed
                        ? mulitem.star_across_breed + "Star"
                        : "- Star"}
                    </div>
                  </div>
                  <div className="col-6 col-md-3 form-group">
                    <div className="pcon-head">Index Reliability</div>
                    <div className="pcon-txt">
                      {multiitem && mulitem.index_re ? mulitem.index_re : ""}
                    </div>
                  </div>
                </div>
              )} */}
              {features.proddetails.category_id == "Dairy" && (
                <div className="row">
                  <div className="col-6 col-md-3 form-group">
                    <div className="pcon-head">{multiitem ? "EBI" : "EBI"}</div>
                    <div className="pcon-txt">
                      {multiitem && mulitem.ebi ? mulitem.ebi : ""}
                    </div>
                  </div>
                  <div className="col-6 col-md-3 form-group">
                    <div className="pcon-head">EBI Rel</div>
                    <div className="pcon-txt">
                      {multiitem && mulitem.ebi_rel ? mulitem.ebi_rel : ""}
                    </div>
                  </div>
                  <div className="col-6 col-md-3 form-group">
                    <div className="pcon-head">Milk Yield (Kgs)</div>
                    <div className="pcon-txt">
                      {multiitem && mulitem.milk_yeild
                        ? mulitem.milk_yeild
                        : ""}
                    </div>
                  </div>
                  <div className="col-6 col-md-3 form-group">
                    <div className="pcon-head">Fertility</div>
                    <div className="pcon-txt">
                      {multiitem && mulitem.fertility ? mulitem.fertility : ""}
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">
                    {multiitem ? "Oldest Test Date" : "TB Test Date"}
                  </div>
                  <div className="pcon-txt">
                    {multiitem
                      ? mulitem.testdate
                        ? moment(mulitem.testdate).format("DD/MM/YY")
                        : ""
                      : moment(features.proddetails.inspectiontime).format(
                          "DD/MM/YY"
                        )}
                  </div>
                </div>
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">
                    {multiitem ? "Least days in Herd" : "Days in Herd"}
                  </div>
                  <div className="pcon-txt">
                    {multiitem
                      ? mulitem.days
                      : TimetoDay(features.proddetails.content_head4)}
                  </div>
                </div>
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">Export</div>
                  <div className="pcon-txt">
                    {multiitem
                      ? mulitem.ex
                      : features.proddetails.future === "1"
                      ? "Yes"
                      : "No"}
                  </div>
                </div>
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">Calf Reg</div>
                  <div className="pcon-txt">
                    {multiitem ? mulitem.cr : features.proddetails.manufacturer}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head"># Movement</div>
                  <div className="pcon-txt">
                    {multiitem ? mulitem.own : features.proddetails.height}
                  </div>
                </div>
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">QA Farm</div>
                  <div className="pcon-txt">
                    {features.proddetails.extra_fields
                      ? JSON.parse(features.proddetails.extra_fields)
                          .qa_farm !== "undefined"
                        ? JSON.parse(features.proddetails.extra_fields).qa_farm
                        : "No"
                      : "No"}
                  </div>
                </div>
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">Section</div>
                  <div className="pcon-txt">
                    {features.proddetails.category_id}
                  </div>
                </div>
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">Type</div>
                  <div className="pcon-txt">
                    {features.proddetails.subcategory}
                  </div>
                </div>
                <div className="col-6 col-md-3 form-group">
                  <div className="pcon-head">Designations/Clubs</div>
                  <div className="pcon-txt">
                    {mulitem.qa ? mulitem.qa : "No"}
                  </div>
                </div>
              </div>
              <div className="pcon-head mt-2">Description</div>
              <p className="pcon-descp mb-4 mt-2">
                {features.proddetails.description
                  ? features.proddetails.description
                  : "No Description"}
              </p>
              <div className="row">
                <div className="col-6 col-md-6 form-group">
                  <div className="pcon-head">Disclaimer</div>
                  <p className="pcon-descp">
                    {features.proddetails.custom_field_4
                      ? features.proddetails.custom_field_4 != "undefined"
                        ? features.proddetails.custom_field_4
                        : "No Disclaimers Submitted"
                      : "No Disclaimers Submitted"}
                    <br></br>
                  </p>
                </div>

                {features.proddetails.content_head3 == "Not Sold by Weight" ? (
                  <div className="col-6 col-md-6 form-group">
                    <div className="pcon-head">Sale Method</div>
                    <div className="pcon-txt">Not Sold by Weight</div>
                  </div>
                ) : (
                  <div className="col-6 col-md-6 form-group">
                    <div className="pcon-head">Weighing Method</div>
                    <div className="pcon-txt">
                      {multiitem
                        ? mulitem.wm
                        : features.proddetails.content_head3}
                    </div>
                  </div>
                )}
              </div>
              <p></p>
              <div className="row">
                {display ? (
                  <div className="col-6 col-md-6 form-group">
                    <div class="pcon-head pvSellerMisc">
                      Member:{" "}
                      {features.proddetails["length"] == 1 ? (
                        <Link
                          to={{
                            pathname: "/seller-profile",
                            state: { profData },
                          }}
                        >
                          {profData.username ? profData.username : "Hi"}
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="pcon-txt">
                      {features.proddetails["length"] == 0 ? (
                        <Link
                          to={{
                            pathname: "/seller-profile",
                            state: { profData },
                          }}
                        >
                          {profData.username}
                        </Link>
                      ) : (
                        profData.first_name +
                        " " +
                        profData.last_name +
                        ", " +
                        profData.city
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* {parseFloat(profData.terms_check) != 1 ? (
                  <> */}
                <div className="col-6 col-md-6 form-group">
                  <div className="pcon-head pvSellerMisc d-flex align-items-center">
                    Seller reviews
                    <a
                      className="review-link  d-flex align-items-center"
                      onClick={viewReviewsClickHanlder}
                    >
                      <span
                        className="material-icons"
                        style={{ paddingRight: "5px" }}
                      >
                        visibility
                      </span>
                      {sellerFeedback.length}
                    </a>
                  </div>
                  <div className="pcon-txt">
                    <Rating
                      name="simple-controlled"
                      value={getSellerRatings()}
                      readOnly={true}
                    />
                  </div>
                </div>
                {/* </>
                ) : (
                  ""
                )} */}
              </div>
              <div className="row">
                <div className="col-md-6 col-6">
                  <div className="pcd-label">
                    <span className="material-icons">location_on</span>
                    {features.proddetails?.state
                      ? CountiesShortform.find(
                          (item) => item.fname == features.proddetails.state
                        ).sname
                      : features.proddetails?.state}
                    , {features.proddetails.location}
                  </div>
                </div>
                <div className="col-md-6 col-6">
                  <div className="pcd-label localLabel">
                    <span className="material-icons">local_shipping</span>{" "}
                    {values2.haulier.replace("per", "/")}
                  </div>
                </div>
              </div>
              {/* {features.proddetails.date_closed ? (
                <div className="pcd-label">
                  <span className="material-icons">date_range</span>
                  Expected delivery dates:{" "}
                  {features.proddetails.date_closed
                    ? sDate(features.proddetails.date_closed)
                    : ""}{" "}
                  to{" "}
                  {features.proddetails.date_closed
                    ? eDate(sDate(features.proddetails.date_closed))
                    : ""}
                </div>
              ) : (
                ""
              )} */}
            </div>
            <div className="col-lg-5 bidHistoryCarousel">
              {moment(features.proddetails.date_added).format(
                "YYYY-MM-DD HH:mm:ss"
              ) < currentDate &&
                features.proddetails.market_status == "open" &&
                features.proddetails.auction == 1 && (
                  <Bidhistory bidHistory={bidApi} />
                )}
            </div>
          </div>

          <div className="qn-head">Have a query? Post here</div>
          <Message
            from_id={state.user.id}
            to_id={""}
            sellFname={features.proddetails?.first_name}
            sellLname={features.proddetails?.last_name}
            sellEmail={features.proddetails.seller_email}
            buyName={localStorage.getItem("user-name")}
            status={features.proddetails.market_status}
            seller_id={features.proddetails?.user_id}
            project_id={features.proid}
            current_id={state.user.id}
          />
          {/* <div className="query-wrapper">
            <div className="msg-wrap">
              <div className="msg-con">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam,
              </div>
              <div className="msg-date">12/08/2020 | 12:20PM</div>
            </div>
            <div className="msg-wrap cu-msg">
              <div className="msg-date">12/08/2020 | 12:25PM</div>
              <div className="msg-con">Lorem ipsum dolor sit amet</div>

              <div className="msg-user">
                S**** R***{" "}
                <span className="material-icons">account_circle</span>
              </div>
            </div>
            <div className="msg-wrap cu-msg">
              <div className="msg-date">12/08/2020 | 12:30PM</div>
              <div className="msg-con">Lorem ipsum dolor sit amet</div>
              <div className="msg-user">
                S**** R***{" "}
                <span className="material-icons">account_circle</span>
              </div>
            </div>
            </div>
            <div className="query-input-wrap">
              <input
                className="form-control"
                placeholder="Type your message here..."
              />
              <button className="blue-btn">SEND</button>
            </div>
          </div> */}
        </div>

        <FFModal
          open={modalopen1}
          handleClose={toggleModal1}
          modaltitle="Message"
        >
          <div style={{ fontWeight: "500" }}>
            <p className="card-text">
              Please register as a Buyer to place a bid & buynow.
            </p>

            <div className="modalCTA d-flex justify-content-end align-items-center">
              <Button onClick={toggleModal1}>Cancel</Button>

              <PrimaryButton label={"Continue"} onsubmit={moveon} />
            </div>
          </div>
        </FFModal>
        <FFModal
          open={modopen1}
          handleClose={toggleMod1}
          modaltitle="Pre-Bidding Deposit"
        >
          <div style={{ fontWeight: "500" }}>
            {depositAmount > 0 ? (
              <p className="card-text">
                You have insufficient deposit on hold to bid. You currently have
                € {callNumber(depositAmount)} on deposit. Please place
                additional funds on deposit to continue bidding. Thank you.
              </p>
            ) : (
              <>
                {/* <p className="card-text">
                  In order to protect all Members you are required to place a
                  10% "Deposit"/"Hold" on account with the 3rd party payment
                  provider prior to bidding. Please ensure you deposit enough
                  funds to cover any and all bidding you intend to make, as if
                  your deposit is insufficient you may not be able to continue
                  bidding.
                </p>
                <p className="card-text">How this works?</p>
                <p className="card-text">
                  If you place € 1,000.00 on "Deposit"/"Hold" you may bid on any
                  lot, or lots, cumulatively up to a total of €10,000.00. If
                  your cumulative bidding exceeds this amount you will be unable
                  to make further bids. Please ensure you deposit sufficient
                  funds to cover all bids you intend to make. You may deposit
                  funds via SEPA bank transfer, or by placing a hold on your
                  card, below. Any funds not used shall be returned at close of
                  last bidding room. Thank you.
                </p> */}
                <p className="card-text">
                  To bid you must first secure a 10% deposit. This empowers all
                  members, ensuring all bids are verified. Buy & Sell in
                  absolute confidence.
                </p>
                <p className="card-text">How it Works:</p>
                <p className="card-text">
                  <ul>
                    <li>
                      If you deposit €1,000, you can bid up to €10,000 in total.
                    </li>
                    <li>
                      If your bids exceed your available deposit, you'll need to
                      deposit further funds to place further bids.Unused
                      deposits are automatically refunded at the end of the
                      sales event.
                    </li>
                  </ul>
                </p>
              </>
            )}
            <p className="card-text">
              {/* Enter the value here: */}
              <div>
                <CustomInput
                  id="bid-amount"
                  name="init_amount"
                  placeholder="Enter amount"
                  value={
                    callNumber(
                      values.init_amount.toString().split(",").join("")
                    ) == 0
                      ? ""
                      : callNumber(
                          values.init_amount.toString().split(",").join("")
                        )
                  }
                  onchange={(e) =>
                    setValues({ ...values, init_amount: e.target.value })
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="material-icons">euro</span>
                      </InputAdornment>
                    ),
                    onKeyPress: NumberOnly,
                  }}
                  disableTooltip="true"
                />
                {!values.init_amount ? (
                  <p className="text-danger">{"Please enter amount"}</p>
                ) : (
                  ""
                )}
                <div className="modalCTA d-flex justify-content-end align-items-center">
                  {!paymentModalOpen ? (
                    <>
                      <Button onClick={toggleMod1}>Cancel</Button>
                      <PrimaryButton
                        label={"Confirm"}
                        onsubmit={values.init_amount ? togglePaymentModal : ""}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </p>
            {paymentModalOpen ? (
              <div className="col-md-12">
                <PaymentCardDeposit
                  sub={addDepositCard}
                  sub2={addDepositSEPA}
                  handleChange={sepaValueChange}
                  loading={paymentLoading}
                  loading2={loading}
                  card={savedCard}
                  checking={getSaved}
                  firstcall={selectSavedCard}
                  acall={getAllCards}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </FFModal>
        {/* <FFModal
          open={modopen2}
          handleClose={toggleMod2}
          modaltitle="Please add Deposit"
        >
          <div>
            <p className="card-text">
              As you have exceeded 10 times the initial deposit, Please add
              another 10% to be held as deposit.
            </p>

            <div className="modalCTA d-flex justify-content-end align-items-center">
              <Button onClick={toggleMod2}>Cancel</Button>
              <PrimaryButton label={"Okay"} onsubmit={togglePaymentModal} />
            </div>
          </div>
        </FFModal> */}

        <FFModal
          open={modalopen2}
          handleClose={toggleModal2}
          modaltitle="Message"
        >
          <div style={{ fontWeight: "500" }}>
            <p className="card-text">
              Are you sure you want to Buy this item Now?
            </p>

            <div className="modalCTA d-flex justify-content-end align-items-center">
              <Button onClick={toggleModal2}>Cancel</Button>

              <PrimaryButton label={"Continue"} onsubmit={buyNow} />
            </div>
          </div>
        </FFModal>

        <FFModal
          open={modalopen3}
          handleClose={toggleModal2}
          modaltitle="Message"
        >
          <div style={{ fontWeight: "500" }}>
            <p className="card-text">Are you sure you want to place a Bid?</p>
            <div className="modalCTA d-flex justify-content-end align-items-center">
              <Button onClick={toggleModal2}>Cancel</Button>

              <PrimaryButton
                label={"Continue"}
                onsubmit={(e) => {
                  setBidMax(false);
                  onsubmitbid(e);
                }}
              />
            </div>
          </div>
        </FFModal>

        <FFModal
          open={modalopen5}
          handleClose={toggleModal2}
          modaltitle="Message"
        >
          <div style={{ fontWeight: "500" }}>
            <p className="card-text">
              Are you sure you want to place a Max Bid?
            </p>

            <div className="modalCTA d-flex justify-content-end align-items-center">
              <Button onClick={toggleModal2}>Cancel</Button>

              <PrimaryButton
                label={"Continue"}
                onsubmit={(e) => {
                  //setBidMax(true);
                  onsubmitbid(e, "max");
                }}
                value={"max"}
              />
            </div>
          </div>
        </FFModal>

        <FFModal
          open={modalopen4}
          handleClose={toggleModal2}
          modaltitle="Message"
        >
          <div style={{ fontWeight: "500" }}>
            <p className="card-text">
              Are you sure you want to place a Quick Bid?
            </p>

            <div className="modalCTA d-flex justify-content-end align-items-center">
              <Button onClick={toggleModal2}>Cancel</Button>

              <PrimaryButton
                label={"Continue"}
                onsubmit={(e) => {
                  setBidMax(false);
                  onsubmitbid(e);
                }}
              />
            </div>
          </div>
        </FFModal>

        <FFModal
          open={modalopen6}
          handleClose={toggleModal2}
          modaltitle="Message"
        >
          <div style={{ fontWeight: "500" }}>
            <p className="card-text">
              We do not have enough information to estimate haulage costs.
              Please update details.
            </p>
            <div className="modalCTA d-flex justify-content-end align-items-center">
              <Button onClick={toggleModal2}>Cancel</Button>
              <PrimaryButton
                label={"Continue"}
                onsubmit={(e) => {
                  props.history.push("/profile");
                }}
              />
            </div>
          </div>
        </FFModal>

        <FFModal
          open={videoModalOpen}
          handleClose={() => setVideoModalOpen(false)}
          noHeader
          // modaltitle="Video"
        >
          <div style={{ fontWeight: "500" }}>
            <button type="button" className="close" onClick={handleClose1}>
              <i className="fa fa-times"></i>
            </button>
            {features.proddetails.videos ? (
              <>
                <video
                  controls
                  width="100%"
                  height="100%"
                  className="video"
                  hidden={!s3_video}
                >
                  <source
                    src={
                      process.env.REACT_APP_S3_BASE_URL +
                      "/farmfayre/" +
                      features.proddetails.videos
                    }
                    type="video/mp4"
                    onError={(e) => setS3video(false)}
                  ></source>
                </video>
                <video
                  controls
                  width="100%"
                  height="100%"
                  className="video"
                  hidden={s3_video}
                >
                  <source
                    src={
                      process.env.REACT_APP_BASE_URL +
                      "/uploads/" +
                      features.proddetails.videos
                    }
                    type="video/mp4"
                  ></source>
                </video>
              </>
            ) : (
              <div>
                <p style={{ paddingLeft: "174px", fontSize: "18px" }}>
                  No video available.
                </p>
              </div>
            )}
          </div>
        </FFModal>

        <FFModal
          open={reviewModalOpen}
          handleClose={closeReviewsModal}
          modaltitle="Reviews"
        >
          <div className="text-right sort-by-sp" style={{ fontWeight: "500" }}>
            <div className="sort-by mt-2 mb-2">
              <label>Sort By</label>
              <select>
                <option>Newest first</option>
              </select>
            </div>
          </div>

          <div className="reviews-wrapper">
            {sellerFeedback &&
              sellerFeedback.map((val) => <Reviews review={val} />)}
          </div>
        </FFModal>

        <FFModal
          open={visitorModalOpen}
          handleClose={vistorModalClosehandler}
          modaltitle="Message"
        >
          <div style={{ fontWeight: "500" }}>
            <p className="card-text">
              If you wish to participate in the Sales Rooms please register as
              Buyer
            </p>

            <div className="modalCTA d-flex justify-content-end align-items-center">
              <Button onClick={vistorModalClosehandler}>Cancel</Button>

              <PrimaryButton
                label={"Register"}
                onsubmit={() => {
                  if (!isAuthenticated()) {
                    window.location.href = "/Reg_tier_1";
                  } else {
                    window.location.href = "/Reg_tier_2";
                  }
                }}
              />
            </div>
          </div>
        </FFModal>

        <FFModal
          open={modalopen7}
          handleClose={toggleModal2}
          modaltitle="Message"
        >
          <div style={{ fontWeight: "500" }}>
            <p className="card-text">
              If you want to add Lot in favourite, please register. Already
              Registered? Login here.
            </p>

            <div className="modalCTA d-flex justify-content-end align-items-center">
              <Button onClick={moveone("log")}>Login</Button>

              <PrimaryButton label={"Register"} onsubmit={moveone("Reg")} />
            </div>
          </div>
        </FFModal>
        <FFModal
          open={modalopen8}
          handleClose={toggleModal2}
          modaltitle="Message"
        >
          <div>
            <p className="card-text feedLotMess">
              <CheckBox value={QATerm} onchange={() => setQATerm(!QATerm)} />
              This lot is a restricted sale, and can ONLY be purchased if you
              have FEEDLOT STATUS.
              <br />
              <br /> DO NOT BID IF YOU ARE NOT A REGISTERED FEEDLOT, as you
              cannot accept delivery, but may be liable for payment.
              <br />
              <br /> Please confirm you hold FEEDLOT STATUS.
            </p>

            <div className="modalCTA d-flex justify-content-end align-items-center">
              <Button onClick={toggleModal2}>Cancel</Button>

              <PrimaryButton
                label={"Continue"}
                dis={QATerm ? false : true}
                onsubmit={handleChange1}
              />
            </div>
          </div>
        </FFModal>
      </div>
    </Layout>
  );
};
const mapStateToProps = (state) => {
  return {
    timer: state.UserReducer.timer || localStorage.getItem("prodtimer"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeTimer: (value) => dispatch(addTimer(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductView);
